import React from 'react'
import Banner7 from '../image/hip.jpg'

const PartialHipReplacement = () => {
    return (
        <div style={{ width: "100%" }}>
            <h1 style={{ textAlign: "center", marginTop: "0rem" }}> Partial Hip Replacement </h1>
            <img
                className="d-block w-100"
                src={Banner7}
                alt=""
                style={{

                    padding: "5rem"

                }}
            />



            <div style={{ margin: "0 3rem" }}>

                <p>If you've fallen or had an injury that resulted in a hip fracture, hip replacement surgery may be in your future. Whether you need partial or total hip replacement surgery to treat a diseased or damaged hip depends on the type and extent of your injury or condition.

                </p>
                <br />
                <p>orthopedic surgeons perform partial hip replacement, technically known as hemiarthroplasty, almost exclusively when the ball-like head of the thighbone (the femoral head) has been fractured or traumatically injured. The procedure replaces the femoral head with a prosthetic implant made of a strong polished metal or ceramic material. The original cup of the hip socket, known as the acetabulum, remains intact.</p>

            </div>
        </div>
    )
}

export default PartialHipReplacement