import React from 'react'
import Banner13 from '../image/Low.jpg'

const LowBackPain = () => {
    return (
        <div style={{ width: "100%" }}>
            <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Low Back Pain</h1>
            <img
                className="d-block w-100"
                src={Banner13}
                alt=""
                style={{

                    padding: "5rem"

                }}
            />



            <div style={{ margin: "0 3rem" }}>

                <p>Lower back pain is very common. It can result from a strain (injury) to muscles or tendons in the back. Other causes include arthritis, structural problems and disk injuries. Pain often gets better with rest, physical therapy and medication.

                </p>


            </div>
        </div>
    )
}

export default LowBackPain