import React from "react";
// import { NavLink } from "react-router-dom";
import "./naavbaar.css";

// import Fb from '../image/fb.png'
import { RiFacebookFill, RiInstagramFill, RiTwitterFill } from "react-icons/ri";

import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <div>
        <div className="naavbaar">
          <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid ">
              <div className="button">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon "></span>
                </button>
              </div>
              <div
                className="collapse navbar-collapse "
                id="navbarSupportedContent"
              >
                <div className="text">
                  <ul
                    // className="navbar-nav me-auto mb-2 mb-lg-0"
                    className="header-section"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "18px",
                      listStyle: "none",
                      fontWeight: "bolder",
                    }}
                  >
                    <li
                      className="nav-item house"
                      style={{ marginLeft: "0rem" }}
                    >
                      <a
                        className="nav-link active text-light "
                        style={{ marginTop: "7px", marginLeft: ".5rem" }}
                        aria-current="page"
                        href="/"
                      >
                        HOME
                      </a>
                    </li>
                    {/* <li className="nav-item about" style={{ marginLeft: "2rem" }}>
                      
                      <a className="nav-link active text-light" style={{ fontSize: "1.2rem" }} aria-current="page" href='....'>ABOUT US</a>
                    </li> */}
                    <li>
                      <Link
                        className="nav-link active text-light "
                        to="/About"
                        style={{
                          // fontSize: "1.3rem",
                          marginLeft: ".5rem",
                          marginTop: "7px",
                        }}
                      >
                        ABOUT
                      </Link>
                    </li>

                    {/* <li className="nav-item desk" style={{ marginLeft: ".5rem" }}>
                      <div class="dropdown" >
                        <a data-bs-toggle="dropdown" className="nav-link active text-light" style={{ fontSize: "1.3rem", marginTop: "5px" }} aria-current="page" href='....'>
                          TREATMENT</a>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{ width: "12rem", backgroundColor: "#3694C8" }}>

                          <li>
                            <Link className="nav-link active text-light" to="/hipreplacement" style={{ fontSize: "1rem", border: "1px solid white" }}>
                              <b> HIP-REPLACEMENT </b>
                            </Link>
                          </li>


                          <li>
                            <Link className="nav-link active text-light " to="/kneereplacement" style={{ fontSize: "1rem", border: "1px solid white" }}>
                              <b> KNEE-REPLACEMENT </b>
                            </Link>
                          </li>

                          <li>
                            <Link className="nav-link active text-light " to="/Kneeartho" style={{ fontSize: "1rem", border: "1px solid white" }}>
                              <b> KNEE-ARTHROSCOPY </b>
                            </Link>
                          </li>

                          <li>
                            <Link className="nav-link active text-light " to="/KneeFracture" style={{ fontSize: "1rem", border: "1px solid white" }}>
                              <b> ANKLE REPAIR </b>
                            </Link>
                          </li>

                          
                        </ul>
                      </div>
                    </li> */}

                    <li>
                      <Link
                        className="nav-link active text-light "
                        to="/Alltreatment"
                        style={{
                          // fontSize: "1.3rem",
                          marginLeft: "0.5rem",
                          marginTop: "7px",
                        }}
                      >
                        TREATMENT
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="nav-link active text-light "
                        to="/Gallery"
                        style={{
                          // fontSize: "1.3rem",
                          marginLeft: "0.5rem",
                          marginTop: "7px",
                        }}
                      >
                        GALLERY
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="nav-link active text-light "
                        to="/Papercutout"
                        style={{
                          // fontSize: "1.3rem",
                          marginLeft: "0.5rem",
                          marginTop: "7px",
                        }}
                      >
                        NEWS AND EVENT
                      </Link>
                    </li>

                    {/* <li className="nav-item circ" style={{ marginLeft: "2rem" }}>
                      <a className="nav-link active text-light" style={{ fontSize: "1.2rem" }} aria-current="page" href='....'><h4> <b>GALLERY</b></h4></a>
                    </li> */}

                    <li>
                      <Link
                        className="nav-link active text-light "
                        to="/Blog"
                        style={{
                          // fontSize: "1.3rem",
                          marginLeft: "0.5rem",
                          marginTop: "7px",
                        }}
                      >
                        TESTIMONIALS
                      </Link>
                    </li>
                    {/* <li className="nav-item pta" style={{ marginLeft: "2rem" }}>
                      <a className="nav-link active text-light" style={{ fontSize: "1.2rem" }} aria-current="page" href='....'><h4 > <b>BLOG</b></h4></a>
                    </li> */}

                    <li>
                      <Link
                        className="nav-link active text-light "
                        to="/Contactus"
                        style={{
                          // fontSize: "1.3rem",
                          marginLeft: "0.5rem",
                          marginTop: "7px",
                        }}
                      >
                        CONTACT
                      </Link>
                    </li>
                    {/* <li className="nav-item admi" style={{ marginLeft: "2rem" }}>
                      <a className="nav-link active text-light" style={{ fontSize: "1.2rem" }} aria-current="page" href='....'><h4> <b>CONTACTUS</b></h4></a>
                    </li> */}

                    <li
                      style={{
                        alignContent: "end",
                        marginLeft: "1.5rem",
                        marginTop: "7px",
                      }}
                    >
                      <a
                        href="https://www.facebook.com/drbones008?mibextid=ZbWKwL"
                        target="_blank"
                      >
                        <RiFacebookFill
                          style={{
                            fontSize: "2rem",
                            // border: "2px solid gray",
                            padding: "0px",
                            margin: "10px",
                            backgroundColor: "#3694C8",
                            color: "white",
                          }}
                        />
                      </a>
                      <a href="">
                        <RiInstagramFill
                          style={{
                            fontSize: "2rem",
                            // border: "2px solid gray",
                            height: "2rem",
                            padding: "0px",
                            margin: "10px",
                            backgroundColor: "#3694C8 ",
                            color: "white",
                          }}
                        />
                      </a>
                      <a href="">
                        <RiTwitterFill
                          style={{
                            fontSize: "2rem",
                            // border: "2px solid gray",

                            padding: "0px",
                            margin: "10px",
                            backgroundColor: "#3694C8",
                            color: "white",
                          }}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
