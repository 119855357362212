import React from 'react'
import Banner6 from '../image/hip.jpg'

const HipReplacement = () => {
    return (
        <div style={{ width: "100%" }}>
            <h1 style={{ textAlign: "center", marginTop: "0rem" }}> Total Hip Replacement </h1>
            <img
                className="d-block w-100"
                src={Banner6}
                alt=""
                style={{

                    padding: "5rem"

                }}
            />



            <div style={{ margin: "0 3rem" }}>

                <p>During hip replacement, a surgeon removes the damaged sections of the hip joint and replaces them with parts usually constructed of metal, ceramic and very hard plastic. This artificial joint (prosthesis) helps reduce pain and improve function.

                </p>
                <br />
                <p>Also called total hip arthroplasty, hip replacement surgery might be an option if hip pain interferes with daily activities and nonsurgical treatments haven't helped or are no longer effective. Arthritis damage is the most common reason to need hip replacement.</p>

            </div>
        </div>
    )
}

export default HipReplacement