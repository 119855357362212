import React, { useState, useEffect } from "react";
import "./Gallery.css";
import Re from "../image/test.png";
import Re1 from "../image/test1.png";
import Re2 from "../image/test2.png";
import Re3 from "../image/test3.png";
import Re4 from "../image/test4.png";
import Re5 from "../image/test5.png";

const Blog = () => {
  const [data, setData] = useState();

  const getDirectorData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_DATABASE_URL}/testimonials?fields=*.*`
    );
    const data = await response.json();
    setData(data.data);
  };

  useEffect(() => {
    getDirectorData();
  }, []);

  //   console.log(data[0]?.testimonial_image?.data?.full_url, "data");

  return (
    <>
      <div
        className="card-container"
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {data?.map((item) => (
          <div
            key={item.id}
            className="card"
            style={{
              flex: "0 0 30%", // Set width of each card to 30%
              maxWidth: "30%",
              padding: "1rem",
              boxSizing: "border-box",
              margin: "1rem", // Add margin between rows
              minWidth: "280px", // Set minimum width for mobile view
            }}
          >
            <div className="" style={{ backgroundColor: "white" }}>
              <p style={{ margin: "1rem" }}>{item.testimonial_message}</p>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  marginTop: "5rem",
                  marginLeft: "1rem",
                }}
              >
                <img
                  style={{
                    border: "2px solid white",
                    borderRadius: "3rem",
                    width: "3rem",
                    height: "3rem",
                  }}
                  src={item?.testimonial_image.data.full_url}
                  alt=""
                />
                <h6 style={{ margin: "1rem", textAlign: "right" }}>
                  {data?.testimonial_name}
                </h6>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Blog;

{
  /* <div className='jkl'>
<div className='jkl1' style={{ backgroundColor: 'white' }}>
    <p style={{ margin: "1rem" }}>My ACL ligament got torn during long jump.I wasn't able to run or jump. I Consulted with  Dr Parwez sir. He advised mri in which my ACL ligament was torn  Arthroscopic surgery done by Dr Parwez sir, he takes great care of his patients  we are fully satisfied with his surgery and treatment. I recommend his name for any patients needing  orthopaedic surgery.Best Sports Injury Specialist in Jabalpur.</p>
    
    <div style={{ display: 'flex', width: "100%", marginLeft: "1rem" }}>
        <img style={{ border: "2px solid white", borderRadius: "3rem", width: "3rem", height: '3rem' }} src={Re} alt="" />


        <h6 style={{ margin: "1rem", textAlign: "right" }}>Rashmi Kewat</h6>
    </div>

</div>
<div className='jkl1' style={{ backgroundColor: 'white' }}>
    <p style={{ margin: "1rem" }}>Its now 3 years when my ACL surgery done under Dr Parwez sir and Dr Chatterjee sir.
        It's not only my ACL reconstruction but life reconstruction. 10th January 2019 is the date when my OT was done three years back. Thanks to Dr Parwez sir and Dr Shahar madam who introduced me with Parwez sir and all went in wonderfully</p>
    


    <div style={{ display: 'flex', width: "100%", marginLeft: "1rem" }}>
        <img style={{ border: "2px solid white", borderRadius: "3rem", width: "3rem", height: '3rem' }} src={Re1} alt="" />


        <h6 style={{ margin: "1rem", textAlign: "right" }}>Sumita n</h6>
    </div>
</div>
<div className='jkl1' style={{ backgroundColor: 'white' }}>
    <p style={{ margin: "1rem" }}>Dr. Parwez is one of the best orthopedic surgeon in Jabalpur. He is very polite and professional at the same time. He explains each and everything very clearly and provides very effective solution. I highly recommend him. All the best Dr. Parwez.</p>
    

    <div style={{ display: 'flex', width: "100%", marginTop: "5rem", marginLeft: "1rem" }}>
        <img style={{ border: "2px solid white", borderRadius: "3rem", width: "3rem", height: '3rem' }} src={Re2} alt="" />
        <h6 style={{ margin: "1rem", textAlign: "right" }}>
            Mayank gupta</h6>
    </div>
</div>
</div> */
}
