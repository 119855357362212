import React from 'react'
import Banner24 from '../image/OrthopaedicTrauma.jpg'

const OrthopaedicTrauma = () => {
    return (
        <div style={{ width: "100%" }}>
            <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Orthopaedic Trauma</h1>
            <img
                className="d-block w-100"
                src={Banner24}
                alt=""
                style={{

                    padding: "5rem"

                }}
            />



            <div style={{ margin: "0 3rem" }}>

                <p>Orthopedic trauma typically refers to an injury caused by some external force, such as a severe fall, a serious accident, or even a violent attack. These injuries occur in the musculoskeletal system, including bones, cartilage, joints, ligaments, muscles, and tendons of the body.
                </p>

            </div>
        </div>
    )
}

export default OrthopaedicTrauma