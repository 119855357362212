import React from 'react'
import Banner21 from '../image/Arthritis.jpg'

const Arthritis = () => {
    return (
        <div style={{ width: "100%" }}>
            <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Arthritis </h1>
            <img
                className="d-block w-100"
                src={Banner21}
                alt=""
                style={{

                    padding: "5rem"

                }}
            />



            <div style={{ margin: "0 3rem" }}>

                <p>Arthritis is the swelling and tenderness of one or more joints. The main symptoms of arthritis are joint pain and stiffness, which typically worsen with age. The most common types of arthritis are osteoarthritis and rheumatoid arthritis.
                </p>

            </div>
        </div>
    )
}

export default Arthritis