import React from 'react'
import Banner16 from '../image/Fractures.png'

const Fractures = () => {
    return (
        <div style={{ width: "100%" }}>
            <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Fractures and Dislocations treatment</h1>
            <img
                className="d-block w-100"
                src={Banner16}
                alt=""
                style={{

                    padding: "5rem"

                }}
            />



            <div style={{ margin: "0 3rem" }}>

                <p>Fractures are breaks or cracks in the bone(s), while dislocations are when a bone moves out of place from its usual connecting joint. Both fractures and dislocations can be very painful, but the symptoms you experience will help determine which injury you may have.


                </p>



            </div>
        </div>
    )
}

export default Fractures