import React from 'react'
import Banner14 from '../image/Neck.jpg'

const NeckPain = () => {
    return (
        <div style={{ width: "100%" }}>
            <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Neck Pain</h1>
            <img
                className="d-block w-100"
                src={Banner14}
                alt=""
                style={{

                    padding: "5rem"

                }}
            />



            <div style={{ margin: "0 3rem" }}>

                <p>Neck pain, or cervicalgia, can last from days to years, depending on the cause. Common causes include physical strain, poor posture, mental stress, osteoarthritis, spinal stenosis, herniated disk, pinched nerve, tumors and other health conditions.

                </p>


            </div>
        </div>
    )
}

export default NeckPain