import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./Gallery.css";

const Gallery = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [data, setData] = useState([]);

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedImage("");
  };

  // ******* for gallery images from directus *****



  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DATABASE_URL}/gallery?fields=*.*`
      );
      const jsonData = await response.json();

      const imageUrls = await Promise.all(
        jsonData.data[0].gallery_images.map(async (item) => {
          const response = await fetch(
            `${process.env.REACT_APP_API_ASSETS}/${item.directus_files_id}`
          );
          const fileData = await response.json();
          return fileData.data.data;
        })
      );

      setData(imageUrls);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // ********************************

  return (
    <>
      <div
        style={{
          backgroundColor: "#3694C8",
          width: "100%",
          height: "7rem",
          paddingTop: "2.0rem",
          paddingLeft: "0rem",
          textAlign: "center",
          color: "white",
        }}
      >
        <h1>Gallery</h1>
      </div>
      <div className="gallery-container">
        {data?.map((image, index) => (
          <div
            key={index}
            onClick={() => handleImageClick(image?.full_url)}
            className="gallery-image"
          >
            <img
              style={{
                height: "12rem",
                width: "12rem",
                margin: "2rem",
                border: "3px solid black",
              }}
              src={image.full_url}
              alt={image.alt}
            />
          </div>
        ))}
      </div>

      <Modal
        size="lg"
        show={showModal}
        onHide={closeModal}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            <img
              style={{
                width: "100%",
                height: "100%",
              }}
              src={selectedImage}
              alt=""
            />
          </Modal.Title>
        </Modal.Header>
      </Modal>
    </>
  );
};

export default Gallery;
