import React, { useState, useEffect } from "react";
import Dr from "../image/Aboutparwez.jpg";
import Dr2 from "../image/de10.jpeg";

const About = () => {
  const [data, setData] = useState();

  const getDirectorData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_DATABASE_URL}/about?fields=*.*`
    );
    const data = await response.json();
    setData(data.data);
  };

  useEffect(() => {
    getDirectorData();
  }, []);


  return (
    <>
      <div
        style={{
          backgroundColor: "#3694C8",
          width: "100%",
          height: "auto",
          paddingTop: "1.5rem",
          paddingLeft: "4rem",
          overflow: "hidden",
          textAlign: "center",
        }}
      >
        <h1 style={{ color: "white", marginBottom: "1.5rem" }}>About Us</h1>
      </div>
      <div style={{ backgroundColor: "lightgray" }}>
        <div className="container text-left ">
          <div className="row about-main">
            <div className="col text-center ">
              <img
                src={data?.[0]?.about_image?.data?.full_url}
                alt="Logo"
                width="100%"
                height="400vh"
                className=" justify-content-center about-image"
              />
            </div>

            <div className="col text-left about-text">
              <h2 style={{ color: "black" }}>Dr. Parwez Qureshi</h2>
              {/* <h4 style={{ color: "#3694C8" }}>Orthopedic and Joint Replacement Surgeon</h4> */}
              {/* <h3 style={{ color: "black" }}> Ortho Cure Clinic</h3> <br /> */}
              {/* 
            <p>Dr. Parwez Qureshi is amongst the few orthopaedic surgeons in Jabalpur regularly performing key-hole surgery (Arthroscopy) of the joints. He treats multiple Ligament injuries, meniscal tears, early osteoarthritis and many other conditions of the knee & shoulders with the help of arthroscopy.He has undergone fellowships & training programmes from reputed institutions across the globe including  Fellowship in Minimally invasive Joint Replacement from Germany . Fellowship in Revision Joint replacement from Hungary, Fellowship in arthroscopy and sports medicine from Kolkata and a Diploma in Sports Medicine from FIFA, Switzerland which has helped him acquire a lot of technical expertise, key to success in orthopaedic surgeries.
Dr. Parwez is adept at performing joint replacement surgeries (both primary and revision) of the knee, hip & shoulders using the latest minimally invasive and painless techniques. He sub-specializes in Sports Medicine offering patients the latest practices & treatments in the management of sports injuries. He is one among few who do arthroscopy surgeries of smaller joints also Ankle & Elbow for sports injuries.
His vast knowledge in sports medicine helped lot of athletes & sports persons to resume their career and excel in it.  His operated patients are playing various sports at state level.</p> */}
              <p>
                <li>
                  Bachelor of Medicine and Bachelor of Surgery (MBBS) from
                  Hassan institute of medical sciences, Hassan , Karnataka
                </li>
                <li>
                  Master of Surgery (M.S.) Orthopaedics from Prestigious Sawai
                  Maan Sing (SMS) Medical college, Jaipur
                </li>
                <li>FIFA diploma in football medicine, Switzerland </li>
                <li>
                  Fellowship in Minimally Invasive Joint Replacement Surgery ,
                  Germany
                </li>
                <li>Fellowship in Arthroscopy surgery, Germany</li>
                <li>Fellowship in Arthroscopy & Sports Medicine, Kolkata</li>
              </p>
              <p>
                
                <b> Experience :</b> 10 Years
              </p>
            </div>
            <div>
              {/* <h3>QUALIFICATIONS - </h3>
              <p>
                <li>Bachelor of Medicine and Bachelor of Surgery (MBBS) from Hassan institute of medical sciences, Hassan , Karnataka </li>
                <li>Master of Surgery (M.S.) Orthopaedics from Prestigious Sawai Maan Sing (SMS) Medical college, Jaipur</li>
                <li>FIFA diploma in football medicine, Switzerland </li>
                <li>Fellowship in Minimally Invasive Joint Replacement Surgery , Germany </li>
                <li>Fellowship in Arthroscopy surgery, Germany</li>
                <li>Fellowship in Arthroscopy & Sports Medicine, Kolkata</li>
              </p> */}

              <p>{data?.[0]?.about_description}</p>

              {/* <p>
                Dr. Parwez Qureshi is amongst the few orthopaedic surgeons in
                Jabalpur regularly performing key-hole surgery (Arthroscopy) of
                the joints. He treats multiple Ligament injuries, meniscal
                tears, early osteoarthritis and many other conditions of the
                knee & shoulders with the help of arthroscopy.He has undergone
                fellowships & training programmes from reputed institutions
                across the globe including Fellowship in Minimally invasive
                Joint Replacement from Germany . Fellowship in Revision Joint
                replacement from Hungary, Fellowship in arthroscopy and sports
                medicine from Kolkata and a Diploma in Sports Medicine from
                FIFA, Switzerland which has helped him acquire a lot of
                technical expertise, key to success in orthopaedic surgeries.
                Dr. Parwez is adept at performing joint replacement surgeries
                (both primary and revision) of the knee, hip & shoulders using
                the latest minimally invasive and painless techniques. He
                sub-specializes in Sports Medicine offering patients the latest
                practices & treatments in the management of sports injuries. He
                is one among few who do arthroscopy surgeries of smaller joints
                also Ankle & Elbow for sports injuries. His vast knowledge in
                sports medicine helped lot of athletes & sports persons to
                resume their career and excel in it. His operated patients are
                playing various sports at state level.
              </p> */}
              {/* <p>
                <li> Dr. Parwez Qureshi is the best orthopedic doctor in Jabalpur. He is director at Ortho Cure Clinic, Jabalpur.</li>
                <li>Dr. Parwez Qureshi is currently working as  Assistant Professor in Department of Orthopaedics, NSCB Medical College Jabalpur.</li>
                <li>Dr. Parwez Qureshi do surgery of all insurance and TPA patients including CGHS, ECHS, ESI and all government scheme (Ayushman Bharat ) in various empanelled hospitals.</li>
                <li>Dr. Parwez Qureshi has 10 years of experience of arthroscopic surgery and has done more the 5000 orthopedic surgeries including knee and shoulder arthroscopic  and joint replacement surgeries. His operated patients are playing various sports at state level.He himself being a sports enthusiast has represented his school and City at Clusters, Zonals and National level athletic meets.</li>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
