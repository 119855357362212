import React from 'react'
import Banner5 from '../image/Knee.jpg'

const KneeReplacement = () => {
    return (
        <div style={{ width: "100%" }}>
            <h1 style={{ textAlign: "center", marginTop: "0rem" }}> Unicondylar Knee Replacement </h1>
            <img
                className="d-block w-100"
                src={Banner5}
                alt=""
                style={{

                    padding: "5rem"

                }}
            />



            <div style={{ margin: "0 3rem" }}>

                <p>During knee replacement surgery, bone and cartilage damaged by osteoarthritis are resurfaced with metal and plastic components. In unicompartmental knee replacement (also called partial knee replacement) only a portion of the knee is resurfaced. This procedure is an alternative to total knee replacement for patients whose disease is limited to just one area of the knee.

                </p>
                <br />
                <p>Because a partial knee replacement is performed through a smaller incision, patients usually spend little or no time in the hospital and return to normal activities sooner than total knee replacement patients.</p>

            </div>
        </div>
    )
}

export default KneeReplacement