import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import './Gallery.css';
import pcut1 from '../image/pcut1.jpeg'
import pcut2 from '../image/pcut2.jpeg'
import pcut3 from '../image/pcut3.jpeg'
import pcut4 from '../image/pcut4.jpeg'
import pcut5 from '../image/pcut5.jpeg'



const Papercutout = () => {
    const [smShow, setSmShow] = useState(false);
    const [lgShow, setLgShow] = useState(false);
    const [mdShow, setMdShow] = useState(false);
    const [xmShow, setXmShow] = useState(false);
    // const [xlShow, setXlShow] = useState(false);
    const [xsShow, setXsShow] = useState(false);

    return (
        <>

            <div style={{ backgroundColor: "#3694C8", width: "100%", height: "7rem", paddingTop: "2.0rem", paddingLeft: "0rem", textAlign: "center", color: "white" }}><h1>NEWS AND EVENT</h1></div>
            <div className="gallerimages">
                <div className="galleyig">
                    <div onClick={() => setSmShow(true)} className="me-2">
                        <img
                            style={{
                                height: "12rem",
                                width: "12rem",
                                margin: "2rem",
                                border: "3px solid black"
                            }}
                            src={pcut1} alt="" />
                    </div>

                    <div onClick={() => setLgShow(true)}><img
                        style={{
                            height: "12rem",
                            width: "12rem",
                            margin: "2rem",
                            border: "3px solid black"
                        }}
                        src={pcut2} alt="" />
                    </div>
                    <div onClick={() => setXsShow(true)}><img
                        style={{
                            height: "12rem",
                            width: "12rem",
                            margin: "2rem",
                            border: "3px solid black"
                        }}
                        src={pcut3} alt="" />
                    </div>


                </div>

                <div className="galleyig">
                    <div onClick={() => setMdShow(true)}><img
                        style={{
                            height: "12rem",
                            width: "12rem",
                            margin: "2rem",
                            border: "3px solid black"
                        }}
                        src={pcut4} alt="" />
                    </div>
                    <div onClick={() => setXmShow(true)}><img
                        style={{
                            height: "12rem",
                            width: "12rem",
                            margin: "2rem",
                            border: "3px solid black"
                        }}
                        src={pcut5} alt="" />
                    </div>
                    {/* <div onClick={() => setXlShow(true)}><img
                        style={{
                            height: "12rem",
                            width: "12rem",
                            margin: "2rem",
                            border: "3px solid black"
                        }}
                        src={Ga5} alt="" />
                    </div> */}
                </div>
            </div>
            <Modal
                size="lg"
                show={smShow}
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header >
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <img
                            style={{

                                width: "100%", height: "100%"
                            }}
                            src={pcut1} alt="" />
                    </Modal.Title>
                </Modal.Header>

            </Modal>
            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header >
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <img
                            style={{

                                width: "100%", height: "100%"
                            }}
                            src={pcut2} alt="" />
                    </Modal.Title>
                </Modal.Header>

            </Modal>

            <Modal
                size="lg"
                show={mdShow}
                onHide={() => setMdShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header >
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <img
                            style={{

                                width: "100%", height: "100%"
                            }}
                            src={pcut4} alt="" />
                    </Modal.Title>
                </Modal.Header>

            </Modal>


            <Modal
                size="lg"
                show={xmShow}
                onHide={() => setXmShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header >
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <img
                            style={{

                                width: "100%", height: "100%"
                            }}
                            src={pcut5} alt="" />
                    </Modal.Title>
                </Modal.Header>

            </Modal>


            {/* <Modal
                size="lg"
                show={xlShow}
                onHide={() => setXlShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header >
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <img
                            style={{

                                width: "100%", height: "100%"
                            }}
                            src={Ga5} alt="" />
                    </Modal.Title>
                </Modal.Header>

            </Modal> */}


            <Modal
                size="lg"
                show={xsShow}
                onHide={() => setXsShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header >
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <img
                            style={{

                                width: "100%", height: "100%"
                            }}
                            src={pcut3} alt="" />
                    </Modal.Title>
                </Modal.Header>

            </Modal>
        </>
    )
}



export default Papercutout