import "./App.css";
import Header from "./Components/Header";
import Naav from "./Components/Naav";
import Banner from "./Components/Banner";
import Treatement from "./Components/Treatement";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Hipreplace from './Components/Hipreplace';
// import Kneereplace from './Components/Kneereplace';
// import Kneeartho from './Components/Kneeartho';
// import KneeFracture from './Components/KneeFracture';
// import KneeJoint from './Components/KneeJoint';
import About from "./Components/About";
import Gallery from "./Components/Gallery";
import Blog from "./Components/Blog";
import Contactus from "./Components/Contactus";
import Bootam from "./Components/Bootam";
import Alltreatment from "./Components/Alltreatment";
import Arthroscopy from "./Components/Arthroscopy";
import SportsInjuries from "./Components/SportsInjuries";
import Ligament from "./Components/Ligament";
import Kneereplace from "./Components/Kneereplace";
import KneeReplacement from "./Components/KneeReplacement";
import HipReplacement from "./Components/HipReplacement";
import PartialHipReplacement from "./Components/PartialHipReplacement";
import RevisionJoint from "./Components/RevisionJoint";
import JointPreservation from "./Components/JointPreservation";
import KneeandShoulder from "./Components/KneeandShoulder";
import Corrective from "./Components/Corrective";
import Deformity from "./Components/Deformity";
import LowBackPain from "./Components/LowBackPain";
import NeckPain from "./Components/NeckPain";
// import Trauma from './Components/Trauma';
import Fractures from "./Components/Fractures";
// import BoneReconstruction from './Components/BoneReconstruction';
import BoneandJoint from "./Components/BoneandJoint";
import Spinepain from "./Components/Spinepain";
import Osteoporosis from "./Components/Osteoporosis";
import Arthritis from "./Components/Arthritis";
import Brokenbones from "./Components/Brokenbones";
import BoneTumour from "./Components/BoneTumour";
import OrthopaedicTrauma from "./Components/OrthopaedicTrauma";
import Papercutout from "./Components/Papercutout";

function App() {
  return (
    <>
      <BrowserRouter>
        <Naav />
        <Header />

        <Routes>
          <Route path="/Contactus" element={<Contactus />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/About" element={<About />} />
          <Route path="/Papercutout" element={<Papercutout />} />
          <Route path="/" element={<Banner />} />

          {/* <Route path="/hipreplacement" element={<Hipreplace />} />
          <Route path="/kneereplacement" element={<Kneereplace />} />
          <Route path="/Kneeartho" element={<Kneeartho />} />
          <Route path="/KneeFracture" element={<KneeFracture />} />
          <Route path="/KneeJoint" element={<KneeJoint />} /> */}
          <Route path="/Alltreatment" element={<Alltreatment />} />
          <Route path="/Arthroscopy" element={<Arthroscopy />} />
          <Route path="/SportsInjuries" element={<SportsInjuries />} />
          <Route path="/Ligament" element={<Ligament />} />
          <Route path="/Kneereplace" element={<Kneereplace />} />
          <Route path="/KneeReplacement" element={<KneeReplacement />} />
          <Route path="/HipReplacement" element={<HipReplacement />} />
          <Route
            path="/PartialHipReplacement"
            element={<PartialHipReplacement />}
          />
          <Route path="/RevisionJoint" element={<RevisionJoint />} />
          <Route path="/JointPreservation" element={<JointPreservation />} />
          <Route path="/KneeandShoulder" element={<KneeandShoulder />} />
          <Route path="/Corrective" element={<Corrective />} />
          <Route path="/Deformity" element={<Deformity />} />
          <Route path="/LowBackPain" element={<LowBackPain />} />
          <Route path="/NeckPain" element={<NeckPain />} />
          {/* <Route path="/Trauma" element={<Trauma />} /> */}
          <Route path="/Fractures" element={<Fractures />} />
          {/* <Route path="/BoneReconstruction" element={<BoneReconstruction />} /> */}
          <Route path="/BoneandJoint" element={<BoneandJoint />} />
          <Route path="/Spinepain" element={<Spinepain />} />
          <Route path="/Osteoporosis" element={<Osteoporosis />} />
          <Route path="/Arthritis" element={<Arthritis />} />
          <Route path="/Brokenbones" element={<Brokenbones />} />
          <Route path="/BoneTumour" element={<BoneTumour />} />
          <Route path="/OrthopaedicTrauma" element={<OrthopaedicTrauma />} />
        </Routes>
        <Bootam />
      </BrowserRouter>
    </>
  );
}

export default App;
