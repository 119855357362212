import React from 'react'
import Banner10 from '../image/Arthroscopy.jpeg'

const KneeandShoulder = () => {
    return (
        <div style={{ width: "100%" }}>
            <h1 style={{ textAlign: "center", marginTop: "0rem" }}> Shoulder  Surgery</h1>
            <img
                className="d-block w-100"
                src={Banner10}
                alt=""
                style={{

                    padding: "5rem"

                }}
            />



            <div style={{ margin: "0 3rem" }}>

                <h3>Shoulder  Surgery</h3>
                <p>Shoulder arthroscopy is surgery that uses a tiny camera called an arthroscope to examine or repair the tissues inside or around your shoulder joint. The arthroscope is inserted through a small cut (incision) in your skin.</p>
                <br />
                <p>Arthroscopy may be recommended for these shoulder problems:

                    A torn or damaged cartilage ring (labrum) or ligaments
                    Shoulder instability, in which the shoulder joint is loose and slides around too much or becomes dislocated (slips out of the ball and socket joint)
                    A torn or damaged biceps tendon
                    A torn rotator cuff
                    A bone spur or inflammation around the rotator cuff
                    Inflammation or damaged lining of the joint, often caused by an illness, such as rheumatoid arthritis
                    Arthritis of the end of the clavicle (collarbone)
                    Loose tissue that needs to be removed
                    Shoulder impingement syndrome, to make more room for the shoulder to move around</p>

            </div>
        </div>
    )
}

export default KneeandShoulder