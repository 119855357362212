import React from 'react'
import { RiFacebookFill, RiTwitterFill, RiYoutubeFill } from "react-icons/ri";

const Contactus = () => {
  return (
    <>
      {/* <div style={{ backgroundColor: "#252525" }}>
        <div className="container text-left ">
          <div className="row">
            <div className="col text-center mt-5 " style={{ widows: "32%" }} >
              <div className='d-flex'>
                <h3 style={{ color: "white" }}>About Hospital</h3>
              </div>

              <div style={{ marginTop: "1.5rem", textAlign: "left" }}>

                <h4 style={{ color: "white" }}>Dr Parwez Qureshi is a fellowship trained Orthopedic and joint replacement surgeon. He has done his orthopedic training  (MS) from SMS Hospital, Jaipur, </h4>
                <button style={{ backgroundColor: "#3694C8", borderRadius: "1rem", marginBottom: "2rem" }}> <h4> Know More</h4></button>
              </div>
            </div>


            <div className="col text-center mt-5 " >
              <div className='d-flex'>

                <h3 style={{ color: "white" }}>Contact Details</h3>
              </div>

              <div style={{ marginTop: "1.5rem", color: "white", textAlign: "left" }}>
                <h4> <b>Address : </b> Ukhri Tiraha , Opposite Global Hospital, Vijay Nagar Jabalpur </h4>
                <h4> <b>Email: </b> drpqmsortho@gmail.com</h4>
                <h4> <b>Phone: </b> 9424527467, 0761-3589157,8989174786</h4>
              </div>
              <div>
                <li>
                  <a href="">
                    <RiTwitterFill
                      style={{
                        fontSize: "2rem",
                        border: "2px solid gray",
                        height: "2rem",
                        padding: "0px",
                        margin: "10px",
                        backgroundColor: 'black '
                      }}
                    />
                  </a>
                  <a href="" >
                    <RiYoutubeFill
                      style={{
                        fontSize: "2rem",
                        border: "2px solid gray",

                        padding: "0px",
                        margin: "10px",
                        backgroundColor: 'black'
                      }}
                    />
                  </a>
                  <a href="" >
                    <RiFacebookFill
                      style={{
                        fontSize: "2rem",
                        border: "2px solid gray",
                        padding: "0px",
                        margin: "10px",
                        backgroundColor: 'black'
                      }}
                    />
                  </a>
                </li>
              </div>
            </div>







            <div className="col text-center mt-5 " >
              <div className='d-flex'>

                <div style={{ width: "32%", margin: "2rem" }}>
                  <div className="aboutonep cmmap">
                    <div className="apmap">
                      <iframe src="https://maps.google.com/maps?q=Ukhri Tiraha , Opposite Global Hospital, Vijay Nagar Jabalpur&t=&z=10&ie=UTF8&iwloc=&output=embed" width="330" height="330" style={{ border: 0, borderRadius: "1rem" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
                    </div>
                  </div>
                </div>
              </div>



            </div>

          </div>
        </div>
      </div> */}
    </>
  )
}

export default Contactus