import Carousel from "react-bootstrap/Carousel";
import { useState, useEffect } from "react";
import "./banner.css";
import "./Gallery.css";
import Banner1 from "../image/ho.webp";
import Banner2 from "../image/ho.webp";
import Banner3 from "../image/h10.jpg";
import Banner4 from "../image/h2.jpg";
import Banner5 from "../image/h3.jpg";
import Banner6 from "../image/h40.jpeg";
import Dr from "../image/de1.jpeg";
import Dr2 from "../image/de10.jpeg";
import Dr1 from "../image/de11.png";
import Ga1 from "../image/g1.jpeg";
import Ga2 from "../image/g2.jpeg";
import Ga3 from "../image/g3.jpeg";
import Ga4 from "../image/g4.jpeg";
import Ga5 from "../image/g5.jpeg";
import Ga6 from "../image/g6.jpeg";
import Ga7 from "../image/g7.jpeg";
import Ga8 from "../image/g8.jpeg";
import Ga9 from "../image/g9.jpeg";
import Ga10 from "../image/g10.jpeg";
import Ga11 from "../image/g11.jpeg";
import Ga12 from "../image/g12.jpeg";
import Gal1 from "../image/gal11.jpeg";
import Gal2 from "../image/gal2.jpeg";
import Gal3 from "../image/gal3.jpeg";
import Re from "../image/test.png";
import Re1 from "../image/test1.png";
import Re2 from "../image/test2.png";
import Banner10 from "../image/knee-new.jpg";
import Banner20 from "../image/h10.jpg";
import Banner30 from "../image/Ligament.jpg";
import Banner40 from "../image/h2.jpg";
import {
  RiFacebookFill,
  RiInstagramFill,
  RiTwitterFill,
  RiWhatsappFill,
} from "react-icons/ri";
import Bann1 from "../image/slider-new.jpeg";
import Bann2 from "../image/slider2.jpg";
import Bann3 from "../image/slider33.jpeg";

function Banner() {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DATABASE_URL}/slider?fields=*.*`
      );
      const jsonData = await response.json();

      const imageUrls = await Promise.all(
        jsonData.data[0].slider_images.map(async (item) => {
          const response = await fetch(
            `${process.env.REACT_APP_API_ASSETS}/${item.directus_files_id}`
          );
          const fileData = await response.json();
          return fileData.data.data;
        })
      );

      setData(imageUrls);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Carousel>
        {data.map((item, index) => (
          <Carousel.Item key={index} interval={2000}>
            <img className="d-block w-100" src={item.full_url} alt="" />
          </Carousel.Item>
        ))}
      </Carousel>

      <div
        className="logo slider-class"
        style={{
          // marginTop: "0rem",
          position: "sticky",
          top: "50px",
        }}
      >
        <div
          style={{
            height: "3rem",
            width: "4rem",
            backgroundColor: "#3694C8",
            borderRadius: "0rem .5rem .5rem 0rem",
          }}
        >
          <a href="">
            <RiInstagramFill
              style={{
                fontSize: "2rem",

                height: "2rem",
                padding: "0px",
                margin: "10px",
                backgroundColor: "#3694C8 ",
                color: "white",
              }}
            />
          </a>
        </div>
        <div
          className="logo"
          style={{
            height: "3rem",
            width: "4rem",
            backgroundColor: "#3694C8",
            marginTop: "0.5rem",
            borderRadius: "0rem .5rem .5rem 0rem",
          }}
        >
          <a href="">
            <RiTwitterFill
              style={{
                fontSize: "2rem",

                padding: "0px",
                margin: "10px",
                backgroundColor: "#3694C8",
                color: "white",
              }}
            />
          </a>
        </div>
        <div
          className="logo"
          style={{
            height: "3rem",
            width: "4rem",
            backgroundColor: "#3694C8",
            marginTop: ".5rem",
            borderRadius: "0rem .5rem .5rem 0rem",
          }}
        >
          <a
            href="https://www.facebook.com/drbones008?mibextid=ZbWKwL"
            target="_blank"
          >
            <RiFacebookFill
              style={{
                fontSize: "2rem",

                padding: "0px",
                margin: "10px",
                backgroundColor: "#3694C8",
                color: "white",
              }}
            />
          </a>
        </div>
        <div
          className="logo"
          style={{
            height: "3rem",
            width: "4rem",
            backgroundColor: "#3694C8",
            marginTop: ".5rem",
            borderRadius: "0rem .5rem .5rem 0rem",
          }}
        >
          <a href="" target="_blank">
            <RiWhatsappFill
              style={{
                fontSize: "2rem",
                padding: "0px",
                margin: "10px",
                backgroundColor: "#3694C8",
                color: "white",
              }}
            />
          </a>
        </div>
      </div>

      {/* ----------------------our Treatement------------------------ */}

      <div className="cardslidersetup mt-5 mb-5 our-treatment">
        <div className="spcialitiesContent text-center">
          <h5>OUR TREATMENT </h5>
          <hr
            width="50"
            align-center
            style={{
              height: "5px",
              background: "green",
              border: "none",
              margin: "auto",
              borderRadius: "5px",
            }}
          />
        </div>

        <div className="jkl">
          <div className="jkl1">
            <img className="d-block" src={Banner10} alt="Second slide" />
            <h4>Arthroscopy</h4>
            <p>
              Arthroscopy is a procedure for diagnosing and treating joint
              problems. A surgeon inserts…
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/Arthroscopy"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1rem",
                  paddingTop: "15px",
                  paddingBottom: "px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>

          <div className="jkl1">
            <img className="d-block" src={Banner20} alt="Second slide" />
            <h4> Sports Injuries</h4>
            <p>
              The term “sports injury” refers to the kinds of injuries that most
              commonly occur during sports or exercise…
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/SportsInjuries"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1rem",
                  paddingTop: "15px",
                  paddingBottom: "px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>

          <div className="jkl1">
            <img className="d-block" src={Banner30} alt="Third slide" />
            <h4>Ligament Reconstruction</h4>
            <p>
              Knee ligament injuries are a common occurrence among athletes,
              however, everyday individuals are also at risk for injuring…
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/Ligament"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1rem",
                  paddingTop: "15px",
                  paddingBottom: "px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>

          <div className="jkl1">
            <img className="d-block" src={Banner40} alt="Third slide" />
            <h4>Total Knee Replacement</h4>
            <p>
              f your knee is severely damaged by arthritis or injury, it may be
              hard for you to perform simple activities...{" "}
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/KneeFracture"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1rem",
                  paddingTop: "15px",
                  paddingBottom: "px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>
        </div>

        <div className="readbtnsecond mb-2">
          <a
            href="/Alltreatment"
            style={{
              display: "flex",
              justifyContent: "center",
              color: "white",
              backgroundColor: "#3694C8",
              textDecoration: "none",
              fontSize: "1rem",
              paddingTop: "15px",
              paddingBottom: "px",
              padding: ".5rem",
              borderRadius: "1rem",
            }}
          >
            <h3>VIEW ALL</h3>
          </a>
        </div>
        {/* <div className="readbtnsecond mb-2">
          <a
            href="/Gallery"
            style={{
              display: "flex",
              justifyContent: "center",
              color: "white",
              backgroundColor: "#3694C8",
              textDecoration: "none",
              fontSize: "1.2rem",
              paddingTop: "18px",
              paddingBottom: "3px",
              padding: ".5rem",
              borderRadius: "1rem"


            }}
          >
            <h3>VIEW MORE</h3>
          </a>
        </div> */}

        {/* <div className="readbtnsecond">
          <button style={{ paddingTop: "1rem" }}> <h4> VIEW ALL TREATMENT </h4></button>
        </div> */}
      </div>

      {/*-------------------end our Treatement--------------------- */}

      {/*-------------------about doctor--------------------- */}

      <div
        style={{ backgroundColor: "lightgray" }}
        className="home-about-section"
      >
        <div className="container text-left ">
          <div className="row home-about-main">
            <div className="col text-center ">
              <img
                src={Dr2}
                alt="Logo"
                width="80%"
                height="400vh"
                className=" justify-content-center home-about-image"
              />
            </div>

            <div className="col text-left  second-div">
              <h2 style={{ color: "black" }}>Dr. Parwez Qureshi</h2>
              {/* <h4 style={{ color: "#3694C8" }}>Orthopedic and Joint Replacement Surgeon</h4> */}
              {/* <h3 style={{ color: "black" }}> Ortho Cure Clinic</h3> <br /> */}
              <li> MS Ortho( SMS Hospital, Jaipur)</li>
              <li>
                {" "}
                Fellowship in minimally invasive Joint Replacement surgery and
                Arthroscopy (Germany)
              </li>
              <li> Fellowship in Arthroscopy & Sports Medicine (Kolkata)</li>
              <li> Consultant Arthroscopy and Joint Replacement</li> <br />
              <p>
                {" "}
                <b> Experience :</b> 10 Years
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div style={{ backgroundColor: "lightgray", width: "100%", display: "flex", textAlign: "left" }}>
        <div style={{ width: "40%" }}>
          <img src={Dr} alt="" style={{
            margin: "5rem",
            height: "30rem", width: "20rem" ,overflow:"hidden"
          }} />
        </div>


        <div style={{ width: "55%", margin: "5rem" , overflow:"hidden"}}>
          <h1 style={{ color: "black" }}>Dr. Romil Rathi</h1>
          <h4 style={{ color: "#3694C8" }}>Orthopedic and Joint Replacement Surgeon</h4>
          <h3 style={{ color: "black" }}> Medicure Multispecialty Clinic</h3> <br />
          <h6> MBBS</h6> <br />
          <h6> MS (Ortho)</h6> <br />
          <h6> SICOT International Fellow (IRCCS Polyclinico San Mateo, Italy)</h6> <br />
          <h6>  Academic Visitor (Hospital for Special Surgery , New York)</h6> <br />
          <h6> Revision Joint Replacement Fellow (Helio Endo Klinic, Germany)</h6> <br />
          <h6> <b> Experience :</b> 10 Years</h6>
        </div>
      </div> */}

      {/*------------------- end about doctor--------------------- */}

      <div className="gallerimages">
        <h2>Gallery</h2>
        <div className="galleyig">
          <div className="oneiggl">
            <img src={Ga1} alt="" />
          </div>
          <div className="oneiggl">
            <img src={Ga2} alt="" />
          </div>
          <div className="oneiggl">
            <img src={Ga3} alt="" />
          </div>
          {/* <div className="oneiggl">
            <img src={Ga4} alt="" />
          </div> */}
        </div>

        <div className="galleyig">
          <div className="oneiggl">
            <img src={Ga5} alt="" />
          </div>
          <div className="oneiggl">
            <img src={Ga6} alt="" />
          </div>
          <div className="oneiggl">
            <img src={Ga7} alt="" />
          </div>
          {/* <div className="oneiggl">
            <img src={Ga8} alt="" />
          </div> */}
        </div>

        <div className="galleyig">
          <div className="oneiggl">
            <img src={Ga9} alt="" />
          </div>
          <div className="oneiggl">
            <img src={Ga10} alt="" />
          </div>
          <div className="oneiggl">
            <img src={Ga11} alt="" />
          </div>
          {/* <div className="oneiggl">
            <img src={Ga12} alt="" />
          </div> */}
        </div>
        <div className="readbtnsecond mb-2">
          <a
            href="/Gallery"
            style={{
              display: "flex",
              justifyContent: "center",
              color: "white",
              backgroundColor: "#3694C8",
              textDecoration: "none",
              fontSize: "1.2rem",
              paddingTop: "18px",
              padding: ".5rem",
              borderRadius: "1rem",
            }}
          >
            <h3>VIEW MORE</h3>
          </a>
        </div>
      </div>

      <div
        className="cardslidersetup "
        style={{ backgroundColor: "lightgray", padding: "2rem" }}
      >
        <div className="spcialitiesContent text-center">
          <h3>OUR Testimonials </h3>
          <hr
            width="50"
            align-center
            style={{
              height: "5px",
              background: "green",
              border: "none",
              margin: "auto",
              borderRadius: "5px",
            }}
          />
        </div>

        {/* optional */}

        <div className="jkl">
          <div className="jkl1" style={{ backgroundColor: "white" }}>
            <p style={{ margin: "1rem" }}>
              My ACL ligament got torn during long jump.I wasn't able to run or
              jump. I Consulted with Dr Parwez sir. He advised mri in which my
              ACL ligament was torn Arthroscopic surgery done by Dr Parwez sir,
              he takes great care of his patients we are fully satisfied with
              his surgery and treatment. I recommend his name for any patients
              needing orthopaedic surgery.Best Sports Injury Specialist in
              Jabalpur.
            </p>
            {/* <button style={{ border: "none" }}>Read More</button> */}
            <div style={{ display: "flex", width: "100%", marginLeft: "1rem" }}>
              <img
                style={{
                  border: "2px solid white",
                  borderRadius: "3rem",
                  width: "3rem",
                  height: "3rem",
                }}
                src={Re}
                alt=""
              />

              <h6 style={{ margin: "1rem", textAlign: "right" }}>
                Rashmi Kewat
              </h6>
            </div>
          </div>
          <div className="jkl1" style={{ backgroundColor: "white" }}>
            <p style={{ margin: "1rem" }}>
              Its now 3 years when my ACL surgery done under Dr Parwez sir and
              Dr Chatterjee sir. It's not only my ACL reconstruction but life
              reconstruction. 10th January 2019 is the date when my OT was done
              three years back. Thanks to Dr Parwez sir and Dr Shahar madam who
              introduced me with Parwez sir and all went in wonderfully
            </p>
            {/* <button style={{ border: "none" }}>Read More</button> */}

            <div style={{ display: "flex", width: "100%", marginLeft: "1rem" }}>
              <img
                style={{
                  border: "2px solid white",
                  borderRadius: "3rem",
                  width: "3rem",
                  height: "3rem",
                }}
                src={Re1}
                alt=""
              />

              <h6 style={{ margin: "1rem", textAlign: "right" }}>Sumita n</h6>
            </div>
          </div>
          <div className="jkl1" style={{ backgroundColor: "white" }}>
            <p style={{ margin: "1rem" }}>
              Dr. Parwez is one of the best orthopedic surgeon in Jabalpur. He
              is very polite and professional at the same time. He explains each
              and everything very clearly and provides very effective solution.
              I highly recommend him. All the best Dr. Parwez.
            </p>
            {/* <button style={{ border: "none" }}>Read More</button> */}

            <div
              style={{
                display: "flex",
                width: "100%",
                marginTop: "5rem",
                marginLeft: "1rem",
              }}
            >
              <img
                style={{
                  border: "2px solid white",
                  borderRadius: "3rem",
                  width: "3rem",
                  height: "3rem",
                }}
                src={Re2}
                alt=""
              />
              <h6 style={{ margin: "1rem", textAlign: "right" }}>
                Mayank gupta
              </h6>
            </div>
          </div>
        </div>

        <div className="readbtnsecond mb-2">
          <a
            href="/Blog"
            style={{
              display: "flex",
              justifyContent: "center",
              color: "white",
              backgroundColor: "#3694C8",
              textDecoration: "none",
              fontSize: "1.2rem",
              paddingTop: "18px",
              padding: ".5rem",
              borderRadius: "1rem",
            }}
          >
            <h3>READ ALL TESTIMONIALS </h3>
          </a>
        </div>
        {/* <button style={{ paddingTop: "1rem" }}> <h4> VIEW ALL TESTIMONIALS </h4></button> */}
      </div>

      {/* 
      <div >
        <p style={{ textAlign: "center", }}>
          <h2> Our Testimonials </h2>
        </p>
        <div className='jkl' style={{ display: "flex" }}>
          <div className='jkl1' style={{
            height: "13rem",
            border: "2px solid #877b7a",
            width: "30%",
            margin: "1rem",
            borderRadius:"1rem"
          }}>
            <div style={{ margin: "1rem" }}>
              <p >My Wife suffering from knee pain, she can't walk easily at home so My relative suggest me for Dr Romil Rathi . Dr suggest Surgary n after Surgary she is absoultly fine now and</p>
              <button style={{border:"none"}}>Read More</button>
              <h6 style={{ margin: "1rem", textAlign: "right" }}>Rahul</h6>
            </div>
          </div>
          <div style={{
            height: "13rem",
            border: "2px solid #877b7a",
            width: "30%",
            margin: "1rem",
            borderRadius:"1rem"
          }}>
            <div style={{ margin: "1rem" }}>
              <p>I visited for my back pain in Medicure multi-speciality clinic. Very good doctor . Recovery very fast . Doc is very polite . He explained very well . Clinics plus point is Pathology </p>
              <button style={{border:"none"}}>Read More</button>
              <h6 style={{ margin: "1rem", textAlign: "right" }}>Rahul</h6>
            </div>
          </div>
          <div style={{
            height: "13rem",
            border: "2px solid #877b7a",
            width: "30%",
            margin: "1rem",
            borderRadius:"1rem"
          }}>
            <div style={{ margin: "1rem" }}>
              <p>Very good experience. Nice treatment.</p>
              <h6 style={{ marginTop: "8rem", textAlign: "right" }}>Rahul</h6>
            </div>
          </div>
        </div>
        <div className="readbtnsecond mb-5">
          <button > <h3 style={{ marginTop: ".6rem" }}> View More </h3></button>
        </div>
      </div> */}

      {/* <div style={{ backgroundColor: "#252525" }}>
        <div className="container text-left ">
          <div className="row">
            <div className="col text-center mt-5 " style={{ widows: "32%" }} >
              <div className='d-flex'>
                <h3 style={{ color: "white" }}>About Hospital</h3>
              </div>

              <div style={{ marginTop: "1.5rem", textAlign: "left" }}>

                <h4 style={{ color: "white" }}>Dr Parwez Qureshi is a fellowship trained Orthopedic and joint replacement surgeon. He has done his orthopedic training  (MS) from SMS Hospital, Jaipur, </h4>
                <button style={{ backgroundColor: "#3694C8", borderRadius: "1rem", marginBottom: "2rem" }}> <h4> Know More</h4></button>
              </div>
            </div>


            <div className="col text-center mt-5 " >
              <div className='d-flex'>

                <h3 style={{ color: "white" }}>Contact Details</h3>
              </div>

              <div style={{ marginTop: "1.5rem", color: "white", textAlign: "left" }}>
                <h4> <b>Address : </b> Ukhri Tiraha , Opposite Global Hospital, Vijay Nagar Jabalpur </h4>
                <h4> <b>Email: </b> drpqmsortho@gmail.com</h4>
                <h4> <b>Phone: </b> 9424527467, 0761-3589157,8989174786</h4>
              </div>
              <div>
                <li>
                  <a href="">
                    <RiTwitterFill
                      style={{
                        fontSize: "2rem",
                        border: "2px solid gray",
                        height: "2rem",
                        padding: "0px",
                        margin: "10px",
                        backgroundColor: 'black '
                      }}
                    />
                  </a>
                  <a href="" >
                    <RiYoutubeFill
                      style={{
                        fontSize: "2rem",
                        border: "2px solid gray",

                        padding: "0px",
                        margin: "10px",
                        backgroundColor: 'black'
                      }}
                    />
                  </a>
                  <a href="" >
                    <RiFacebookFill
                      style={{
                        fontSize: "2rem",
                        border: "2px solid gray",
                        padding: "0px",
                        margin: "10px",
                        backgroundColor: 'black'
                      }}
                    />
                  </a>
                </li>
              </div>
            </div>







            <div className="col text-center mt-5 " >
              <div className='d-flex'>

                <div style={{ width: "32%", margin: "2rem" }}>
                  <div className="aboutonep cmmap">
                    <div className="apmap">
                      <iframe src="https://maps.google.com/maps?q=Ukhri Tiraha , Opposite Global Hospital, Vijay Nagar Jabalpur&t=&z=10&ie=UTF8&iwloc=&output=embed" width="330" height="330" style={{ border: 0, borderRadius: "1rem" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
                    </div>
                  </div>
                </div>
              </div>



            </div>

          </div>
        </div>
      </div> */}

      {/* <div style={{ backgroundColor: "#252525", width: "100%", display: "flex", textAlign: "left" }}>
        <div style={{ width: "32%", color: "white", margin: "2rem" }}>
          <h3>About Hospital</h3>
          <h4>Dr. Romil Rathi is a fellowship trained Orthopedic and joint replacement surgeon. He has done his orthopedic training  (MS) from Jahaharlal Nehru Medical College , Wardha and further training in joint replacement from Deenanath Mangeshkar Hospital , Pune. After training in India…</h4>
          <button style={{ backgroundColor: "#3694C8", borderRadius: "1rem" }}> <h4> Know More</h4></button>
        </div>

        <div style={{ width: "32%", color: "white", margin: "2rem" }}>
          <h3>Contact Details</h3>
          <h4> <b>Address : </b> Ground floor, Medicure Multispeciality clinic, near Lokmat Square, Gandhi bagh, Ramdaspeth, Nagpur, Maharashtra 440012</h4>
          <h4> <b>Email: </b> dr.romilrathi@gmail.com</h4>
          <h4> <b>Phone: </b> 0712 – 2445671 / 72, 9975768365, 91466 77836</h4>
        </div>
        <div style={{ width: "32%", margin: "2rem" }}>
          <div className="aboutonep cmmap">
            <div className="apmap">
              <iframe src="https://maps.google.com/maps?q=Medicure Multispeciality clinic, near Lokmat Square, Gandhi bagh, Ramdaspeth, Nagpur, Maharashtra 440012&t=&z=10&ie=UTF8&iwloc=&output=embed" width="330" height="450" style={{ border: 0, borderRadius: "1rem" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div style={{ backgroundColor: "black", width: "100%", textAlign: "center", padding: "2rem" }}>
        <h5 style={{ color: "wheat" }}>© 2022 Dr. Romil Rathi Medicure Multispecialty Clinic | All Rights Reserved. | Designed by Pdigiworld
        </h5>

      </div> */}
    </>
  );
}

export default Banner;
