import React from 'react'
import Banner11 from '../image/Corrective.gif'

const Corrective = () => {
    return (
        <div style={{ width: "100%" }}>
            <h1 style={{ textAlign: "center", marginTop: "0rem" }}> Corrective Osteotomies</h1>
            <img
                className="d-block w-100"
                src={Banner11}
                alt=""
                style={{

                    padding: "5rem"

                }}
            />



            <div style={{ margin: "0 3rem" }}>

                <p>An osteotomy is a surgery that cuts bone to restore more normal alignment. In the hips and knees, this can realign the leg and improve gait and as well as reduce pain. Also, when knee or hip replacement is not a good option due to young age, you may want to consider an osteotomy.

                </p>


            </div>
        </div>
    )
}

export default Corrective