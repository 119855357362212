import React from 'react'
import Banner19 from '../image/Spine.jpg'

const Spinepain = () => {
    return (
        <div style={{ width: "100%" }}>
            <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Spine pain</h1>
            <img
                className="d-block w-100"
                src={Banner19}
                alt=""
                style={{

                    padding: "5rem"

                }}
            />



            <div style={{ margin: "0 3rem" }}>

                <p>Spinal pain in the lumbar region (lower back) and cervical region (neck) are highly prevalent and are often the causes for many lost work days. Lumbar muscle strains and sprains are the most common causes of low back pain. The thoracic spine can also be a site of spinal pain, but because it is much more rigid, the thoracic spinal area is much less frequently injured than the lumbar and cervical spine.
                </p>
            </div>
        </div>
    )
}

export default Spinepain