import React from 'react'
import Banner20 from '../image/Osteoporosis.webp'

const Osteoporosis = () => {
    return (
        <div style={{ width: "100%" }}>
            <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Osteoporosis Medical and Surgical </h1>
            <img
                className="d-block w-100"
                src={Banner20}
                alt=""
                style={{

                    padding: "5rem"

                }}
            />



            <div style={{ margin: "0 3rem" }}>

                <p>Osteoporosis is a metabolic bone disease that affects men and women as they grow older. Caucasian and Asian women and most at risk for this disease, and while it’s not common for children to develop it, it is possible.
                </p>
                <br />
                <p>It affects mostly men and women as they get older because they begin to lose bone mass, making their bones brittle, weak, and more susceptible to injuries, most often in the spine, wrists and hips. Bone tissue is constantly regenerating in the body, meaning old bone is removed and replaced with new bone. New bone is created fast than old bone is broken down in young people, but when a person reaches their twenties, they begin to lose bone mass faster than it’s created. This continues as the person ages.</p>
                <br />
                <p>Symptoms don’t typically show up during the early stages of osteoporosis, so patients don’t know they’re dealing with the disease until they suffer from a fracture from a minor injury.

                </p>
            </div>
        </div>
    )
}

export default Osteoporosis