import React from 'react'
import Banner8 from '../image/Revision.png'

const RevisionJoint = () => {
    return (
        <div style={{ width: "100%" }}>
            <h1 style={{ textAlign: "center", marginTop: "0rem" }}> Revision Joint Replacement Surgeries </h1>
            <img
                className="d-block w-100"
                src={Banner8}
                alt=""
                style={{

                    padding: "5rem"

                }}
            />



            <div style={{ margin: "0 3rem" }}>

                <p>A revision joint replacement means that surgery must be done to replace a worn-out joint replacement. Also called revision arthroplasty, revision joint replacement is often more complicated than the initial joint replacement and the revision surgery may have less predictable results.

                </p>
                <br />


            </div>
        </div>
    )
}

export default RevisionJoint