import React from "react";
import "./naav.css";
import nav from "../image/Ortho_Logo.png";
// import { RiFacebookFill, RiTwitterFill, RiYoutubeFill } from "react-icons/ri";

const Naav = () => {
  return (
    <>
      {/* <div class="sticky-sm-top" style={{ marginTop: "0rem" }}>
        <li>
          <div style={{ height: "4ren", width: "4rem", backgroundColor: "#00494f", marginTop: "0rem" }}>
            <a href="">
              <RiTwitterFill
                style={{
                  fontSize: "2rem",
                  border: "2px solid gray",
                  height: "2rem",
                  padding: "0px",
                  margin: "10px",
                  backgroundColor: 'black '
                }}
              />
            </a>
          </div>
          <div style={{ height: "4ren", width: "4rem", backgroundColor: "#00494f", marginTop: "1rem" }}>
            <a href="" >
              <RiYoutubeFill
                style={{
                  fontSize: "2rem",
                  border: "2px solid gray",

                  padding: "0px",
                  margin: "10px",
                  backgroundColor: 'black'
                }}
              />
            </a>
          </div>
          <div style={{ height: "4ren", width: "4rem", backgroundColor: "#00494f", marginTop: "1rem" }}>
            <a href="">
              <RiFacebookFill
                style={{
                  fontSize: "2rem",
                  border: "2px solid gray",
                  padding: "0px",
                  margin: "10px",
                  backgroundColor: 'black'
                }}
              />
            </a>
          </div>
        </li>
        </div> */}

      <div
        className="nv"
        style={{
          textAlign: "center",
        }}
      >
        <nav className="navbar navbar-expand-lg bg-white container-fluid">
          <div className="principal1">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
              <li className="new-navv">
                <img
                  src={nav}
                  alt="Logo"
                  width="50%"
                  height="auto"
                  // style={{ marginTop: "-2rem" }}
                />
              </li>
            </ul>
          </div>

          <div className="principal">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  aria-current="page"
                  style={{ marginTop: "-1rem" }}
                  href="...."
                >
                  For Appointment : +919424527467
                </a>
                <a
                  className="nav-link active"
                  aria-current="page"
                  style={{ marginTop: "-1rem" }}
                  href="...."
                >
                  For Appointment : 0761-3589157
                </a>
                <a
                  className="nav-link active"
                  aria-current="page"
                  style={{ marginTop: "-1rem" }}
                  href="...."
                >
                  Whatssapp / Emergency : +918989174786
                </a>
                <a
                  className="nav-link active"
                  aria-current="page"
                  style={{ marginTop: "-1rem" }}
                  href="..."
                >
                  drpqmsortho@gmail.com
                </a>
              </li>

              <ul>
                <h5>Afternoon 2-3 pm (Prior Appointment Only)</h5>
                <h5>Evening 5 - 9 pm</h5>
              </ul>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Naav;
