import React from 'react'
import Banner1 from '../image/Arthroscopy-1.jpg'

const Arthroscopy = () => {
    return (
        <div style={{ width: "100%" }}>
            <h1 style={{ textAlign: "center", marginTop: "0rem" }}> Arthroscopy </h1>
            <img
                className="d-block w-100"
                src={Banner1}
                alt=""
                style={{

                    padding: "5rem"

                }}
            />



            <div style={{ margin: "0 3rem" }}>

                <p>Arthroscopy  is a procedure for diagnosing and treating joint problems. A surgeon inserts a narrow tube attached to a fiber-optic video camera through a small incision — about the size of a buttonhole. The view inside your joint is transmitted to a high-definition video monitor.

                </p>
                <br />
                <p>Arthroscopy allows the surgeon to see inside your joint without making a large incision. Surgeons can even repair some types of joint damage during arthroscopy, with pencil-thin surgical instruments inserted through additional small incisions.</p>
            </div>
        </div>
    )
}

export default Arthroscopy