import React from "react";
import Banner1 from "../image/knee-new.jpg";
import Banner2 from "../image/h10.jpg";
import Banner3 from "../image/Ligament.jpg";
import Banner4 from "../image/h2.jpg";
import Banner5 from "../image/Knee.jpg";
import Banner6 from "../image/hip.jpg";
import Banner7 from "../image/hip.jpg";
import Banner8 from "../image/Revision.png";
import Banner9 from "../image/Joint.jpeg";
import Banner10 from "../image/Arthroscopy.jpeg";
import Banner11 from "../image/Corrective.gif";
import Banner12 from "../image/Deformity.jpg";
import Banner13 from "../image/Low.jpg";
import Banner14 from "../image/Neck.jpg";
// import Banner15 from '../image/Trauma.jpg'
import Banner16 from "../image/Fractures.png";
import Banner17 from "../image/Bone.webp";
import Banner18 from "../image/Bone.jpg";
import Banner19 from "../image/Spine.jpg";
import Banner20 from "../image/Osteoporosis.webp";
import Banner21 from "../image/Arthritis.jpg";
import Banner22 from "../image/Broken.jpg";
import Banner23 from "../image/BoneTumour.jpeg";
import Banner24 from "../image/OrthopaedicTrauma.jpg";
import Banner25 from "../image/Soft.jpeg";

const Alltreatment = () => {
  return (
    <>
      <div className="cardslidersetup mt-5 mb-5">
        <div className="spcialitiesContent text-center">
          <h5>OUR TREATMENT </h5>
          <hr
            width="50"
            align-center
            style={{
              height: "5px",
              background: "green",
              border: "none",
              margin: "auto",
              borderRadius: "5px",
            }}
          />
        </div>

        {/* optional */}

        <div className="jkl">
          <div className="jkl1">
            <img className="d-block" src={Banner1} alt="Second slide" />
            <h4>Arthroscopy</h4>
            <p>
              Arthroscopy is a procedure for diagnosing and treating joint
              problems. A surgeon inserts…
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/Arthroscopy"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  paddingTop: "18px",
                  paddingBottom: "0px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>

          <div className="jkl1">
            <img className="d-block" src={Banner2} alt="Second slide" />
            <h4> Sports Injuries</h4>
            <p>
              The term “sports injury” refers to the kinds of injuries that most
              commonly occur during sports or exercise…
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/SportsInjuries"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  paddingTop: "18px",
                  paddingBottom: "0px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>

          <div className="jkl1">
            <img className="d-block" src={Banner3} alt="Third slide" />
            <h4>Ligament Reconstruction</h4>
            <p>
              Anterior cruciate ligament reconstruction is a surgical tissue
              graft replacement of the anterior cruciate ligament…
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/Ligament"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  paddingTop: "18px",
                  paddingBottom: "0px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>
        </div>

        <div className="jkl">
          <div className="jkl1">
            <img className="d-block" src={Banner5} alt="Second slide" />
            <h4>Unicondylar Knee Replacement </h4>
            <p>
              During knee replacement surgery, bone and cartilage damaged by
              osteoarthritis are resurfaced with metal…
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/KneeReplacement"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  paddingTop: "18px",
                  paddingBottom: "0px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>

          <div className="jkl1">
            <img className="d-block" src={Banner6} alt="Second slide" />
            <h4>Total Hip Replacement</h4>
            <p>
              During hip replacement, a surgeon removes the damaged sections of
              the hip joint and replaces them with parts usually…
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/HipReplacement"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  paddingTop: "18px",
                  paddingBottom: "0px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>

          <div className="jkl1">
            <img className="d-block" src={Banner7} alt="Third slide" />
            <h4>Partial Hip Replacement</h4>
            <p>
              If you've fallen or had an injury that resulted in a hip fracture,
              hip replacement surgery may be in your future.…
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/PartialHipReplacement"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  paddingTop: "18px",
                  paddingBottom: "0px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>
        </div>

        <div className="jkl">
          <div className="jkl1">
            <img className="d-block" src={Banner9} alt="Second slide" />
            <h4>Joint Preservation Surgery</h4>
            <p>
              Joint preservation is the use of non-surgical or surgical means to
              preserve a deteriorating…
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/JointPreservation"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  paddingTop: "18px",
                  paddingBottom: "0px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>

          <div className="jkl1">
            <img className="d-block" src={Banner10} alt="Second slide" />
            <h4>Shoulder Surgery</h4>
            <p>
              Shoulder arthroscopy is surgery that uses a tiny camera called an
              arthroscope to examine or repair the tissues inside or around…
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/KneeandShoulder"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  paddingTop: "18px",
                  paddingBottom: "0px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>

          <div className="jkl1">
            <img className="d-block" src={Banner11} alt="Third slide" />
            <h4>Corrective Osteotomies</h4>
            <p>
              An osteotomy is a surgery that cuts bone to restore more normal
              alignment. In the hips and knees…
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/Corrective"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  paddingTop: "18px",
                  paddingBottom: "0px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>
        </div>

        <div className="jkl">
          <div className="jkl1">
            <img className="d-block" src={Banner13} alt="Second slide" />
            <h4>Low Back Pain</h4>
            <p>
              Lower back pain is very common. It can result from a strain
              (injury) to muscles or tendons in the back.…
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/LowBackPain"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  paddingTop: "18px",
                  paddingBottom: "0px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>

          <div className="jkl1">
            <img className="d-block" src={Banner14} alt="Second slide" />
            <h4>Neck Pain</h4>
            <p>
              Neck pain, or cervicalgia, can last from days to years, depending
              on the cause. Common causes include…
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/NeckPain"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  paddingTop: "18px",
                  paddingBottom: "0px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>

          {/* <div className='jkl1'>
                        <img
                            className="d-block"
                            src={Banner15}
                            alt="Third slide"
                        />
                        <h4>Trauma</h4>
                        <p>Trauma is an emotional response to a terrible event like an accident, rape, or natural disaster.…</p>
                        <div className="readbtnsecond mb-2">
                            <a
                                href="/Trauma"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    color: "white",
                                    backgroundColor: "#3694C8",
                                    textDecoration: "none",
                                    fontSize: "1.2rem",
                                    paddingTop: "18px",
                                    paddingBottom: "0px",
                                    padding: ".5rem",
                                    borderRadius: "1rem"
                                }}
                            >
                                <h6>VIEW MORE</h6>
                            </a>
                        </div>
                    </div> */}

          <div className="jkl1">
            <img className="d-block" src={Banner16} alt="Third slide" />
            <h4>Fractures and Dislocations treatment</h4>
            <p>
              Fractures are breaks or cracks in the bone(s), while dislocations
              are when a bone moves out of place...{" "}
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/Fractures"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  paddingTop: "18px",
                  paddingBottom: "0px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>
        </div>

        <div className="jkl">
          {/* <div className='jkl1'>
                        <img
                            className="d-block"
                            src={Banner17}
                            alt="Second slide"
                        />
                        <h4>Bone Reconstruction surgeries</h4>
                        <p>Bone grafting is a surgical procedure that uses transplanted bone to repair and rebuild diseased or damaged…</p>
                        <div className="readbtnsecond mb-2">
                            <a
                                href="/BoneReconstruction"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    color: "white",
                                    backgroundColor: "#3694C8",
                                    textDecoration: "none",
                                    fontSize: "1.2rem",
                                    paddingTop: "18px",
                                    paddingBottom: "0px",
                                    padding: ".5rem",
                                    borderRadius: "1rem"
                                }}
                            >
                                <h6>VIEW MORE</h6>
                            </a>
                        </div>
                    </div> */}

          <div className="jkl1">
            <img className="d-block" src={Banner18} alt="Second slide" />
            <h4>Bone and Joint Infections</h4>
            <p>
              Bone infection is most often caused by bacteria. But it can also
              be caused by fungi or other germs.…
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/BoneandJoint"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  paddingTop: "18px",
                  paddingBottom: "0px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>

          <div className="jkl1">
            <img className="d-block" src={Banner19} alt="Third slide" />
            <h4>Spine pain</h4>
            <p>
              Spinal pain in the lumbar region (lower back) and cervical region
              (neck) are highly prevalent…
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/Spinepain"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  paddingTop: "18px",
                  paddingBottom: "0px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>

          <div className="jkl1">
            <img className="d-block" src={Banner20} alt="Third slide" />
            <h4>Osteoporosis Medical and Surgical</h4>
            <p>
              Osteoporosis is a metabolic bone disease that affects men and
              women as they grow older....{" "}
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/Osteoporosis"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  paddingTop: "18px",
                  paddingBottom: "0px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>
        </div>

        <div className="jkl">
          <div className="jkl1">
            <img className="d-block" src={Banner21} alt="Second slide" />
            <h4>Arthritis </h4>
            <p>
              Arthritis is the swelling and tenderness of one or more joints.
              The main symptoms of arthritis are joint…
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/Arthritis"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  paddingTop: "18px",
                  paddingBottom: "0px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>

          <div className="jkl1">
            <img className="d-block" src={Banner22} alt="Second slide" />
            <h4>Broken bones surgeries</h4>
            <p>
              The three main treatment options for bone fractures are: Casting.
              Open reduction, and internal fixation…
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/Brokenbones"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  paddingTop: "18px",
                  paddingBottom: "0px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>

          <div className="jkl1">
            <img className="d-block" src={Banner24} alt="Third slide" />
            <h4>Orthopaedic Trauma</h4>
            <p>
              Orthopedic trauma typically refers to an injury caused by some
              external force, such as a severe fall...{" "}
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/OrthopaedicTrauma"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  paddingTop: "18px",
                  paddingBottom: "0px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>
        </div>

        <div className="jkl">
          <div className="jkl1">
            <img className="d-block" src={Banner23} alt="Third slide" />
            <h4>Bone Tumour</h4>
            <p>
              Bone tumors develop when cells within a bone divide
              uncontrollably, forming a lump or mass of abnormal tissue.…
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/BoneTumour"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  paddingTop: "18px",
                  paddingBottom: "0px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>
          <div className="jkl1">
            <img className="d-block" src={Banner4} alt="Third slide" />
            <h4>Total Knee Replacement</h4>
            <p>
              f your knee is severely damaged by arthritis or injury, it may be
              hard for you to perform simple activities...{" "}
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/Kneereplace"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  paddingTop: "18px",
                  paddingBottom: "0px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>
          <div className="jkl1">
            <img className="d-block" src={Banner8} alt="Third slide" />
            <h4>Revision Joint Replacement Surgeries</h4>
            <p>
              A revision joint replacement means that surgery must be done to
              replace ...{" "}
            </p>
            <div className="readbtnsecond mb-2">
              <a
                href="/RevisionJoint"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#3694C8",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  paddingTop: "18px",
                  paddingBottom: "0px",
                  padding: ".5rem",
                  borderRadius: "1rem",
                }}
              >
                <h6>VIEW MORE</h6>
              </a>
            </div>
          </div>
        </div>
        {/* <div className='jkl1'>
                    <img
                        className="d-block"
                        src={Banner12}
                        alt="Third slide"
                    />
                    <h4>Deformity Correction</h4>
                    <p>Deformity correction is a procedure to straighten a bone that is bent or twisted in a way that is not normal... </p>
                    <div className="readbtnsecond mb-2">
                        <a
                            href="/Deformity"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                color: "white",
                                backgroundColor: "#3694C8",
                                textDecoration: "none",
                                fontSize: "1.2rem",
                                paddingTop: "18px",
                                paddingBottom: "0px",
                                padding: ".5rem",
                                borderRadius: "1rem"


                            }}
                        >
                            <h6>VIEW MORE</h6>
                        </a>
                    </div>
                </div> */}

        {/* <div className='jkl'>
                    <div className='jkl1'>
                        <img
                            className="d-block"
                            src={Banner25}
                            alt="Second slide"
                        />
                        <h4>Soft tissue injuries</h4>
                        <p>Many activities can lead to soft-tissue damage of muscles, ligaments, and tendons. The result can be pain…</p>
                        <div className="readbtnsecond mb-2">
                            <a
                                href="/hipreplacement"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    color: "white",
                                    backgroundColor: "#3694C8",
                                    textDecoration: "none",
                                    fontSize: "1.2rem",
                                    paddingTop: "18px",
                                    paddingBottom: "0px",
                                    padding: ".5rem",
                                    borderRadius: "1rem"
                                }}
                            >
                                <h6>VIEW MORE</h6>
                            </a>
                        </div>
                    </div>



                </div> */}
      </div>

      {/* <div>
                <div style={{ backgroundColor: "#3694C8", width: "100%", height: "auto", paddingTop: "1.5rem", paddingLeft: "4rem", overflow: "hidden", textAlign: "center" }}><h1
                    style={{ color: "white", marginBottom: "1.5rem" }}>Arthroscopy</h1>
                </div>
                <hr />
                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}> Arthroscopy </h1>
                    <img
                        className="d-block w-100"
                        src={Banner1}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>Arthroscopy (ahr-THROS-kuh-pee) is a procedure for diagnosing and treating joint problems. A surgeon inserts a narrow tube attached to a fiber-optic video camera through a small incision — about the size of a buttonhole. The view inside your joint is transmitted to a high-definition video monitor.

                        </p>
                        <br />
                        <p>Arthroscopy allows the surgeon to see inside your joint without making a large incision. Surgeons can even repair some types of joint damage during arthroscopy, with pencil-thin surgical instruments inserted through additional small incisions.</p>
                    </div>
                </div>
                <hr />
                <hr />


                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}> Sports Injuries </h1>
                    <img
                        className="d-block w-100"
                        src={Banner2}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>The term “sports injury” refers to the kinds of injuries that most commonly occur during sports or exercise, but they are not limited to athletes. Factory workers get tennis elbow, painters get shoulder injuries, and gardeners develop tendinitis, even though they may not participate in sports. Ultimately, however, “sports injuries” refers to those that occur in active individuals. This health topic focuses on the most common types of sports injuries—those that affect the musculoskeletal system. The musculoskeletal system is the network of muscles, tendons, ligaments, bones, and other tissues that provides the body with stability and enables movement.

                        </p>
                        <br />
                        <p>Sports injuries are divided into two broad categories, acute and chronic injuries. Acute injuries happen suddenly, such as when a person falls,  receives a blow, or twists a joint, while chronic injuries usually result from overuse of one area of the body and develop gradually over time. Examples of acute injuries are sprains and dislocations, while some common chronic injuries are shin splints and stress fractures.</p>
                    </div>
                </div>
                <hr />
                <hr />



                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}> Ligament Reconstruction </h1>
                    <img
                        className="d-block w-100"
                        src={Banner3}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>Anterior cruciate ligament reconstruction is a surgical tissue graft replacement of the anterior cruciate ligament, located in the knee, to restore its function after an injury. The torn ligament can either be removed from the knee, or preserved before reconstruction through an arthroscopic procedure.

                        </p>

                    </div>
                </div>
                <hr />
                <hr />


                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}> Total Knee Replacement </h1>
                    <img
                        className="d-block w-100"
                        src={Banner4}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>If your knee is severely damaged by arthritis or injury, it may be hard for you to perform simple activities, such as walking or climbing stairs. You may even begin to feel pain while you are sitting or lying down.

                        </p>
                        <br />
                        <p>If nonsurgical treatments like medications and using walking supports are no longer helpful, you may want to consider total knee replacement surgery. Joint replacement surgery is a safe and effective procedure to relieve pain, correct leg deformity, and help you resume normal activities.</p>

                    </div>
                </div>
                <hr />
                <hr />



                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}> Unicondylar Knee Replacement </h1>
                    <img
                        className="d-block w-100"
                        src={Banner5}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>During knee replacement surgery, bone and cartilage damaged by osteoarthritis are resurfaced with metal and plastic components. In unicompartmental knee replacement (also called partial knee replacement) only a portion of the knee is resurfaced. This procedure is an alternative to total knee replacement for patients whose disease is limited to just one area of the knee.

                        </p>
                        <br />
                        <p>Because a partial knee replacement is performed through a smaller incision, patients usually spend little or no time in the hospital and return to normal activities sooner than total knee replacement patients.</p>

                    </div>
                </div>
                <hr />
                <hr />


                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}> Total Hip Replacement </h1>
                    <img
                        className="d-block w-100"
                        src={Banner6}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>During hip replacement, a surgeon removes the damaged sections of the hip joint and replaces them with parts usually constructed of metal, ceramic and very hard plastic. This artificial joint (prosthesis) helps reduce pain and improve function.

                        </p>
                        <br />
                        <p>Also called total hip arthroplasty, hip replacement surgery might be an option if hip pain interferes with daily activities and nonsurgical treatments haven't helped or are no longer effective. Arthritis damage is the most common reason to need hip replacement.</p>

                    </div>
                </div>
                <hr />
                <hr />


                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}> Partial Hip Replacement </h1>
                    <img
                        className="d-block w-100"
                        src={Banner7}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>If you've fallen or had an injury that resulted in a hip fracture, hip replacement surgery may be in your future. Whether you need partial or total hip replacement surgery to treat a diseased or damaged hip depends on the type and extent of your injury or condition.

                        </p>
                        <br />
                        <p>HonorHealth's orthopedic surgeons perform partial hip replacement, technically known as hemiarthroplasty, almost exclusively when the ball-like head of the thighbone (the femoral head) has been fractured or traumatically injured. The procedure replaces the femoral head with a prosthetic implant made of a strong polished metal or ceramic material. The original cup of the hip socket, known as the acetabulum, remains intact.</p>

                    </div>
                </div>
                <hr />
                <hr />


                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}> Revision Joint Replacement Surgeries </h1>
                    <img
                        className="d-block w-100"
                        src={Banner8}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>A revision joint replacement means that surgery must be done to replace a worn-out joint replacement. Also called revision arthroplasty, revision joint replacement is often more complicated than the initial joint replacement and the revision surgery may have less predictable results.

                        </p>
                        <br />
                        <p>People may refer to a revision joint replacement as a repeat joint replacement, or a replacement of a joint replacement. There is no limit to the number of revision replacements that can be performed, although they generally become more difficult each time.</p>

                    </div>
                </div>
                <hr />
                <hr />


                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}> Joint Preservation Surgery</h1>
                    <img
                        className="d-block w-100"
                        src={Banner9}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>Joint preservation is the use of non-surgical or surgical means to preserve a deteriorating joint to delay or avoid joint replacement surgery.

                        </p>
                        <p>Joint preservation procedures reduce the risk of complications from implant surgery and infection.</p>
                        <br />
                        <p>When cartilage deteriorates due to osteoarthritis, it causes persistent joint pain that interferes with daily life. Our consultants will aim to restore normal movement and alleviate pain to the joint whether it’s a shoulder, hip, knee or ankle.</p>

                    </div>
                </div>
                <hr />
                <hr />


                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}> Knee and Shoulder  Surgery</h1>
                    <img
                        className="d-block w-100"
                        src={Banner10}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>
                        <h3>Knee  Surgery</h3>
                        <p>Knee replacement, also called knee arthroplasty or total knee replacement, is a surgical procedure to resurface a knee damaged by arthritis. Metal and plastic parts are used to cap the ends of the bones that form the knee joint, along with the kneecap. This surgery may be considered for someone who has severe arthritis or a severe knee injury.

                        </p>
                        <br />
                        <h3>Shoulder  Surgery</h3>
                        <p>Shoulder arthroscopy is a type of surgery used to diagnose and treat shoulder problems. Your healthcare provider repairs your shoulder through tiny incisions. Shoulder arthroscopy is an outpatient procedure, meaning you can go home the same day. Though full recovery can take weeks or months, exercise and physical therapy can help with healing.</p>

                    </div>
                </div>
                <hr />
                <hr />


                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}> Corrective Osteotomies</h1>
                    <img
                        className="d-block w-100"
                        src={Banner11}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>An osteotomy is a surgery that cuts bone to restore more normal alignment. In the hips and knees, this can realign the leg and improve gait and as well as reduce pain. Also, when knee or hip replacement is not a good option due to young age, you may want to consider an osteotomy.

                        </p>


                    </div>
                </div>
                <hr />
                <hr />


                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Deformity Correction</h1>
                    <img
                        className="d-block w-100"
                        src={Banner12}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>Deformity correction is a procedure to straighten a bone that is bent or twisted in a way that is not normal. After the bone is straightened, the arm, leg, or foot has normal alignment and function. Deformities can be corrected in two different ways:
                            <li>All at once during a surgical procedure (called acute correction)</li>
                            <li> Slowly over the course of several weeks or months (called gradual correction)</li>

                        </p>


                    </div>
                </div>
                <hr />
                <hr />


                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Low Back Pain</h1>
                    <img
                        className="d-block w-100"
                        src={Banner13}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>Lower back pain is very common. It can result from a strain (injury) to muscles or tendons in the back. Other causes include arthritis, structural problems and disk injuries. Pain often gets better with rest, physical therapy and medication.

                        </p>


                    </div>
                </div>
                <hr />
                <hr />


                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Neck Pain</h1>
                    <img
                        className="d-block w-100"
                        src={Banner14}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>Neck pain, or cervicalgia, can last from days to years, depending on the cause. Common causes include physical strain, poor posture, mental stress, osteoarthritis, spinal stenosis, herniated disk, pinched nerve, tumors and other health conditions.

                        </p>


                    </div>
                </div>
                <hr />
                <hr />


                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Trauma</h1>
                    <img
                        className="d-block w-100"
                        src={Banner15}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>Trauma is an emotional response to a terrible event like an accident, rape, or natural disaster. Immediately after the event, shock and denial are typical. Longer term reactions include unpredictable emotions, flashbacks, strained relationships, and even physical symptoms like headaches or nausea.

                        </p>
                        <br />
                        <p>While these feelings are normal, some people have difficulty moving on with their lives. Psychologists can help them find constructive ways of managing their emotions.</p>


                    </div>
                </div>
                <hr />
                <hr />


                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Fractures and Dislocations treatment</h1>
                    <img
                        className="d-block w-100"
                        src={Banner16}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>Fractures are breaks or cracks in the bone(s), while dislocations are when a bone moves out of place from its usual connecting joint. Both fractures and dislocations can be very painful, but the symptoms you experience will help determine which injury you may have.


                        </p>



                    </div>
                </div>
                <hr />
                <hr />


                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Bone Reconstruction surgeries</h1>
                    <img
                        className="d-block w-100"
                        src={Banner17}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>Bone grafting is a surgical procedure that uses transplanted bone to repair and rebuild diseased or damaged bones. A bone graft is a choice for repairing bones almost anywhere in your body. Your surgeon might take bone from your hips, legs, or ribs to perform the graft. Sometimes, surgeons also use bone tissue donated from cadavers to perform bone grafting.
                        </p>
                        <br />
                        <p>Most of your skeleton consists of bone matrix. This is the hard material that helps give the bones their strength. Inside the matrix are living bone cells. These make and maintain this matrix. The cells in this matrix can help repair and heal bone when necessary.</p>



                    </div>
                </div>
                <hr />
                <hr />


                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Bone and Joint Infections</h1>
                    <img
                        className="d-block w-100"
                        src={Banner18}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>Bone infection is most often caused by bacteria. But it can also be caused by fungi or other germs. When a person has osteomyelitis: Bacteria or other germs may spread to a bone from infected skin, muscles, or tendons next to the bone.
                        </p>
                    </div>
                </div>
                <hr />
                <hr />


                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Spine pain</h1>
                    <img
                        className="d-block w-100"
                        src={Banner19}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>Spinal pain in the lumbar region (lower back) and cervical region (neck) are highly prevalent and are often the causes for many lost work days. Lumbar muscle strains and sprains are the most common causes of low back pain. The thoracic spine can also be a site of spinal pain, but because it is much more rigid, the thoracic spinal area is much less frequently injured than the lumbar and cervical spine.
                        </p>
                    </div>
                </div>
                <hr />
                <hr />


                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Osteoporosis Medical and Surgical </h1>
                    <img
                        className="d-block w-100"
                        src={Banner20}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>Osteoporosis is a metabolic bone disease that affects men and women as they grow older. Caucasian and Asian women and most at risk for this disease, and while it’s not common for children to develop it, it is possible.
                        </p>
                        <br />
                        <p>It affects mostly men and women as they get older because they begin to lose bone mass, making their bones brittle, weak, and more susceptible to injuries, most often in the spine, wrists and hips. Bone tissue is constantly regenerating in the body, meaning old bone is removed and replaced with new bone. New bone is created fast than old bone is broken down in young people, but when a person reaches their twenties, they begin to lose bone mass faster than it’s created. This continues as the person ages.</p>
                        <br />
                        <p>Symptoms don’t typically show up during the early stages of osteoporosis, so patients don’t know they’re dealing with the disease until they suffer from a fracture from a minor injury.

                        </p>
                    </div>
                </div>
                <hr />
                <hr />


                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Arthritis </h1>
                    <img
                        className="d-block w-100"
                        src={Banner21}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>Arthritis is the swelling and tenderness of one or more joints. The main symptoms of arthritis are joint pain and stiffness, which typically worsen with age. The most common types of arthritis are osteoarthritis and rheumatoid arthritis.
                        </p>

                    </div>
                </div>
                <hr />
                <hr />


                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Broken bones surgeries</h1>
                    <img
                        className="d-block w-100"
                        src={Banner22}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>The three main treatment options for bone fractures are: Casting. Open reduction, and internal fixation- this involves a surgery to repair the fracture-frequently, metal rods, screws or plates are used to repair the bone, and remain in place, under the skin, after the surgery
                        </p>

                    </div>
                </div>
                <hr />
                <hr />


                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Bone Tumour</h1>
                    <img
                        className="d-block w-100"
                        src={Banner23}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>Bone tumors develop when cells within a bone divide uncontrollably, forming a lump or mass of abnormal tissue.
                        </p>
                        <br />
                        <p>Most bone tumors are benign (not cancerous). Benign tumors are usually not life-threatening and, in most cases, will not spread to other parts of the body. Depending upon the type of tumor, treatment options are wide-ranging — from simple observation to surgery to remove the tumor.

                        </p>
                        <br />
                        <p>Some bone tumors are malignant (cancerous). Malignant bone tumors can metastasize — or cause cancer cells to spread throughout the body. In almost all cases, treatment for malignant tumors involves a combination of chemotherapy, radiation, and surgery.

                        </p>

                    </div>
                </div>
                <hr />
                <hr />


                <div style={{ width: "100%" }}>
                    <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Orthopaedic Trauma</h1>
                    <img
                        className="d-block w-100"
                        src={Banner24}
                        alt=""
                        style={{

                            padding: "5rem"

                        }}
                    />



                    <div style={{ margin: "0 3rem" }}>

                        <p>Orthopedic trauma typically refers to an injury caused by some external force, such as a severe fall, a serious accident, or even a violent attack. These injuries occur in the musculoskeletal system, including bones, cartilage, joints, ligaments, muscles, and tendons of the body.
                        </p>

                    </div>
                </div>
                <hr />
                <hr />


                
            </div> */}
    </>
  );
};

export default Alltreatment;
