import React from 'react'
import Banner18 from '../image/Bone.jpg'

const BoneandJoint = () => {
    return (
        <div style={{ width: "100%" }}>
            <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Bone and Joint Infections</h1>
            <img
                className="d-block w-100"
                src={Banner18}
                alt=""
                style={{

                    padding: "5rem"

                }}
            />



            <div style={{ margin: "0 3rem" }}>

                <p>Bone infection is most often caused by bacteria. But it can also be caused by fungi or other germs. When a person has osteomyelitis: Bacteria or other germs may spread to a bone from infected skin, muscles, or tendons next to the bone.
                </p>
            </div>
        </div>
    )
}

export default BoneandJoint