import React from 'react'
import Banner9 from '../image/Joint.jpeg'

const JointPreservation = () => {
    return (
        <div style={{ width: "100%" }}>
            <h1 style={{ textAlign: "center", marginTop: "0rem" }}> Joint Preservation Surgery</h1>
            <img
                className="d-block w-100"
                src={Banner9}
                alt=""
                style={{

                    padding: "5rem"

                }}
            />



            <div style={{ margin: "0 3rem" }}>

                <p>Joint preservation is the use of non-surgical or surgical means to preserve a deteriorating joint to delay or avoid joint replacement surgery.

                </p>
                <p>Joint preservation procedures reduce the risk of complications from implant surgery and infection.</p>
                <br />
                <p>When cartilage deteriorates due to osteoarthritis, it causes persistent joint pain that interferes with daily life. Our consultants will aim to restore normal movement and alleviate pain to the joint whether it’s a shoulder, hip, knee or ankle.</p>

            </div>
        </div>
    )
}

export default JointPreservation