import React from 'react'
import Banner22 from '../image/Broken.jpg'

const Brokenbones = () => {
    return (
        <div style={{ width: "100%" }}>
            <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Broken bones surgeries</h1>
            <img
                className="d-block w-100"
                src={Banner22}
                alt=""
                style={{

                    padding: "5rem"

                }}
            />



            <div style={{ margin: "0 3rem" }}>

                <p>The three main treatment options for bone fractures are: Casting. Open reduction, and internal fixation- this involves a surgery to repair the fracture-frequently, metal rods, screws or plates are used to repair the bone, and remain in place, under the skin, after the surgery
                </p>

            </div>
        </div>
    )
}

export default Brokenbones