import React from 'react'
import Banner3 from '../image/Ligament.jpg'

const Ligament = () => {
    return (
        <div style={{ width: "100%" }}>
            <h1 style={{ textAlign: "center", marginTop: "0rem" }}> Ligament Reconstruction </h1>
            <img
                className="d-block w-100"
                src={Banner3}
                alt=""
                style={{

                    padding: "5rem"

                }}
            />



            <div style={{ margin: "0 3rem" }}>

                <p>Knee ligament injuries are a common occurrence among athletes, however, everyday individuals are also at risk for injuring one or more of their ligaments just by doing everyday activities and functions such as running, jumping, and climbing stairs. Knee ligament injuries can affect the ACL (anterior cruciate ligament), PCL (posterior cruciate ligament), MCL (medial collateral ligament) or LCL (lateral collateral ligament). A knee ligament injury can be isolated to one specific ligament, or it can be a more complex, multi-ligament injury where more than one ligament is involved. In many cases, when multiple ligaments are affected, other issues with surrounding tissue, muscle, and bone can arise.

                </p> <br />
                <p>Following a thorough physical examination, Dr. Parwez will determine if an isolated ligament injury is present, or if multiple ligaments are involved. He is skilled at performing ligament reconstruction.</p>
                <br /><p>Isolated Ligament Reconstructions
                </p>
                <li>ACL Reconstruction
                </li>
                <li>Revision ACL Reconstruction
                </li>
                <li>LCL Reconstruction
                </li>
                <li>PCL Reconstruction
                </li>
                <li>MCL Reconstruction
                </li>
                <li>Complex and Multi-Ligament Reconstructions

                </li>
                <li>Complex Knee Surgery
                </li>
                <li>Posterolateral Reconstruction
                </li>
                <li>Biplanar Osteotomy</li>

            </div>
        </div>
    )
}

export default Ligament