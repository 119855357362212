import React from 'react'
import Banner12 from '../image/Deformity.jpg'

const Deformity = () => {
    return (
        <div style={{ width: "100%" }}>
            <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Deformity Correction</h1>
            <img
                className="d-block w-100"
                src={Banner12}
                alt=""
                style={{

                    padding: "5rem"

                }}
            />



            <div style={{ margin: "0 3rem" }}>

                <p>Deformity correction is a procedure to straighten a bone that is bent or twisted in a way that is not normal. After the bone is straightened, the arm, leg, or foot has normal alignment and function. Deformities can be corrected in two different ways:
                    <li>All at once during a surgical procedure (called acute correction)</li>
                    <li> Slowly over the course of several weeks or months (called gradual correction)</li>

                </p>


            </div>
        </div>
    )
}

export default Deformity