import React from 'react'
import Banner2 from '../image/h10.jpg'

const SportsInjuries = () => {
    return (
        <div style={{ width: "100%" }}>
            <h1 style={{ textAlign: "center", marginTop: "0rem" }}> Sports Injuries </h1>
            <img
                className="d-block w-100"
                src={Banner2}
                alt=""
                style={{

                    padding: "5rem"

                }}
            />



            <div style={{ margin: "0 3rem" }}>

                <p>The term “sports injury” refers to the kinds of injuries that most commonly occur during sports or exercise, but they are not limited to athletes. Factory workers get tennis elbow, painters get shoulder injuries, and gardeners develop tendinitis, even though they may not participate in sports. Ultimately, however, “sports injuries” refers to those that occur in active individuals. This health topic focuses on the most common types of sports injuries—those that affect the musculoskeletal system. The musculoskeletal system is the network of muscles, tendons, ligaments, bones, and other tissues that provides the body with stability and enables movement.

                </p>
                <br />
                <p>Sports injuries are divided into two broad categories, acute and chronic injuries. Acute injuries happen suddenly, such as when a person falls,  receives a blow, or twists a joint, while chronic injuries usually result from overuse of one area of the body and develop gradually over time. Examples of acute injuries are sprains and dislocations, while some common chronic injuries are shin splints and stress fractures.</p>
            </div>
        </div>
    )
}

export default SportsInjuries