import React from 'react'
import Banner4 from '../image/h2.jpg'

const Kneereplace = () => {
    return (
        <>
            {/* <div style={{ backgroundColor: "#3694C8", width: "100%", height: "7rem",paddingTop:"1.5rem",paddingLeft:"4rem" }}><h1>Knee Replacement</h1></div> */}

            <div style={{ backgroundColor: "#3694C8", width: "100%", height: "auto", paddingTop: "1.5rem", paddingLeft: "4rem", overflow: "hidden", textAlign: "center" }}><h1
                style={{ color: "white", marginBottom: "1.5rem" }}>Knee Replacement</h1></div>


            <div style={{ width: "100%" }}>
                <img
                    className="d-block w-100"
                    src={Banner4}
                    alt=""
                    style={{

                        padding: "5rem"

                    }}
                />
                <h1 style={{ textAlign: "center", marginTop: "0rem" }}> Knee Replacement </h1>
                <div style={{ margin: "3rem" }}>

                    <h5>How We Carry Out Joint Replacement Surgery</h5>
                    <p>During your consultation, you will be given all the information you need about the procedure of your particular joint surgery. However, there are some techniques that we use to make your recovery time as short as possible and the relief as immediate as we can. <br />
                        Where possible, hip surgery will be completed using minimally invasive techniques. This means that a hip prosthesis will be inserted through an incision that is generally less than 10cm. This involves more splitting and less cutting of the muscles, meaning that there is less blood loss, faster recovery and a more cosmetic scar.You may need to undergo knee surgery for a number of reasons, and so the type of implant or replacement will reflect this..</p>
                    <br />
                    <h5>Knee Replacement Surgery</h5>
                    <p>This is a hinge joint. It is lined by articular cartilage and has two fibro-cartilage structures (menisci) which lead to enhanced stability, transfer of joint forces and nutrition of the joint. Stability is further achieved by the cruciate ligaments in the centre and collateral ligaments at the periphery of the joint, as well as surrounding muscles. <br />
                        Knee replacement surgery is usually necessary when the knee joint is worn or damaged so that your mobility is reduced and you are in pain even while resting. The most common reason for knee replacement surgery is osteoarthritis. Knee pain can significantly affect your mobility and overall quality of life. Consult with the best knee surgeon and take the right treatment</p>
                </div>

            </div>
        </>
    )
}

export default Kneereplace