import React from "react";
import { RiFacebookFill, RiInstagramFill, RiTwitterFill } from "react-icons/ri";

const Bootam = () => {
  return (
    <>
      <div style={{ backgroundColor: "#252525" }}>
        <div className="container text-left ">
          <div className="row">
            <div className="col text-center mt-5 " style={{ widows: "32%" }}>
              <div className="d-flex">
                <h3 style={{ color: "white" }}>About Clinic</h3>
              </div>

              <div style={{ marginTop: "1.5rem", textAlign: "left" }}>
                <p style={{ color: "white" }}>
                  Dr Parwez Qureshi is a fellowship trained Orthopedic and joint
                  replacement surgeon. He has done his orthopedic training (MS)
                  from SMS Hospital, Jaipur,{" "}
                </p>
                <div className="readbtnsecond mb-2">
                  <a
                    href="/About"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "white",
                      backgroundColor: "#3694C8",
                      textDecoration: "none",
                      fontSize: "1.2rem",
                      paddingTop: "19px",
                      padding: ".5rem",
                      borderRadius: "1rem",
                    }}
                  >
                    <h3>VIEW MORE</h3>
                  </a>
                </div>
              </div>
            </div>

            <div className="col text-center mt-5 ">
              <div className="d-flex">
                <h3 style={{ color: "white" }}>Contact Details</h3>
              </div>

              <div
                style={{
                  marginTop: "1.5rem",
                  color: "white",
                  textAlign: "left",
                }}
              >
                <p>
                  {" "}
                  <h5> Address : </h5> Ukhri Tiraha , Opposite Global Hospital,
                  Vijay Nagar Jabalpur{" "}
                </p>
                <p>
                  {" "}
                  <h5> Email: </h5> drpqmsortho@gmail.com
                </p>
                <p>
                  {" "}
                  <h5> Phone:</h5> 9424527467, 0761-3589157,8989174786
                </p>
              </div>
              <div>
                <li>
                  <a href="">
                    <RiInstagramFill
                      style={{
                        fontSize: "2rem",

                        height: "2rem",
                        padding: "0px",
                        margin: "10px",
                        backgroundColor: "#252525 ",
                        color: "white",
                      }}
                    />
                  </a>
                  <a href="" target="_blank">
                    <RiTwitterFill
                      style={{
                        fontSize: "2rem",

                        padding: "0px",
                        margin: "10px",
                        backgroundColor: "#252525",
                        color: "white",
                      }}
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/drbones008?mibextid=ZbWKwL"
                    target="_blank"
                  >
                    <RiFacebookFill
                      style={{
                        fontSize: "2rem",

                        padding: "0px",
                        margin: "10px",
                        backgroundColor: "#252525",
                        color: "white",
                      }}
                    />
                  </a>
                </li>
              </div>
            </div>

            <div className="col text-center mt-5 ">
              <div className="d-flex">
                <div style={{ width: "32%" }}>
                  <div className="aboutonep cmmap">
                    <div className="apmap">
                      <iframe
                        src="https://maps.google.com/maps?q=Ukhri Tiraha , Opposite Global Hospital, Vijay Nagar Jabalpur&t=&z=10&ie=UTF8&iwloc=&output=embed"
                        width="330"
                        height="330"
                        style={{ border: 0, borderRadius: "1rem" }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "black",
          width: "100%",
          textAlign: "center",
          padding: "2rem",
        }}
      >
        <h5 style={{ color: "wheat" }}>
          © 2022 Dr. Parwez Qureshi Ortho Cure Clinic | All Rights Reserved. |
          Designed by Maitretech.com
        </h5>
      </div>
    </>
  );
};

export default Bootam;
