import React from 'react'
import Banner23 from '../image/BoneTumour.jpeg'

const BoneTumour = () => {
    return (
        <div style={{ width: "100%" }}>
            <h1 style={{ textAlign: "center", marginTop: "0rem" }}>Bone Tumour</h1>
            <img
                className="d-block w-100"
                src={Banner23}
                alt=""
                style={{

                    padding: "5rem"

                }}
            />



            <div style={{ margin: "0 3rem" }}>

                <p>Bone tumors develop when cells within a bone divide uncontrollably, forming a lump or mass of abnormal tissue.
                </p>
                <br />
                <p>Most bone tumors are benign (not cancerous). Benign tumors are usually not life-threatening and, in most cases, will not spread to other parts of the body. Depending upon the type of tumor, treatment options are wide-ranging — from simple observation to surgery to remove the tumor.

                </p>
                <br />
                <p>Some bone tumors are malignant (cancerous). Malignant bone tumors can metastasize — or cause cancer cells to spread throughout the body. In almost all cases, treatment for malignant tumors involves a combination of chemotherapy, radiation, and surgery.

                </p>

            </div>
        </div>
    )
}

export default BoneTumour